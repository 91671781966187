// useAuthRedirect.ts
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteRegistry from 'routes/routeRegistry';
import { LocalStorageService } from 'utils/storage/LocalStorageService';

const localStorageService = LocalStorageService();
const useAuthRedirect = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const accessToken = localStorageService.getAccessToken();
        const paths: string[] = Object.values(RouteRegistry.auth.paths).map(pathObj => pathObj.path);

        if (!accessToken && !paths.includes(location.pathname)) {
            navigate(RouteRegistry.auth.paths.signIn.path);
        }
    }, [navigate]);
};

export default useAuthRedirect;
