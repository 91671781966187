import { Button, CustomFileInput, Heading, Icon, Modal, TabButton, Typhography } from 'dfh-ui-library';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { language } from '../helpers/language';
import UploadCardList from 'components/InfoCards/UploadCardList';
import ExistingCardList from 'components/InfoCards/ExistingCardList';
import { ApiResponse, IExistingResourceRequest, IImageCreateRequest } from 'utils/types/image.type';
import moment from 'moment';
import { ResourceCardProps, ResourceChooseCardProps } from 'utils/types/patient.type';

interface ReleaseInfo {
    isOpenModal: boolean;
    handleCancelButton?: () => void;
    signedUrl?: string;
    handleConfirmButton?: (req: IImageCreateRequest) => any;
    setViewReleaseInfoPdf?: (show: boolean) => void;
    setPdfReleaseInfoUrl?: (url: string) => void;
    updateCaseManagerInfo?: (info: ResourceCardProps[]) => void;
    currentResourcesInfo?: ResourceCardProps[];
    showPreview?: (fileId: string, uploadType?: string) => void;
    navigateToLetter?: (idx?: number) => void;
    currentExcmgIndex?: number;
    currentExistingRes?: ResourceChooseCardProps[];
    uploadResourceInfoForm: (data: IExistingResourceRequest) => Promise<ApiResponse | undefined>;
    patientId?: string;
}

export const ReleaseInfoModal = (infoProps: ReleaseInfo) => {
    const [isOpen, _setIsOpen] = useState(infoProps.isOpenModal);
    const { handleCancelButton } = infoProps;
    const [selectedValues, setSelectedValues] = useState<string>('upload');
    const [uploadedData, setUploadedData] = useState<ResourceCardProps[]>([]);
    const [fileNme, setFileName] = useState<string | null>(null);
    const [fileContent, setFileContent] = useState<string | null>(null);
    const [checkedIds, setCheckedIds] = useState<string[]>([]);
    const handleCheckboxChange = (id: string, checked: boolean) => {
        setCheckedIds((prevState) => (checked ? [...prevState, id] : prevState.filter((_id) => _id !== id)));
    };

    const filterOptions = [
        {
            displayText: 'Upload scan/image',
            value: 'upload',
        },
        {
            displayText: 'Choose from existing',
            value: 'choose',
        },
    ];

    const onClickTab = (item: string) => {
        setSelectedValues(item);
    };

    useEffect(() => {
        if (infoProps.currentResourcesInfo && infoProps.currentResourcesInfo?.length > 0) {
            setUploadedData(infoProps.currentResourcesInfo);
        }
    }, []);

    const generateRandomString = (length: number = 6): string => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const handleFileChange = (file: File | null) => {
        if (file) {
            setFileName(file.name);

            const tempUpload = [...uploadedData];
            const isUploaded = tempUpload.some((values: ResourceCardProps) => values.title === file.name);

            if (!isUploaded) {
                const fileData: ResourceCardProps = {
                    title: file.name,
                    fileSize: `${(file.size / 1024).toFixed(2).toString()} KB`,
                    progress: 10,
                    isShowPreview: false,
                    dateCreated: moment(new Date().toISOString()).format('MM/DD/YYYY'),
                    signedUrl: '',
                    fileId: generateRandomString(),
                };
                setUploadedData([...uploadedData, fileData]);
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const result = reader.result as string | null;
                if (result) {
                    setFileContent(result);
                }
            };
        }
    };

    const updatePropgress = (progress: number, isShowPreview: boolean, id: string = '', signedUrl: string = '') => {
        setUploadedData((prevData) =>
            prevData.map((file) =>
                file.title === fileNme
                    ? { ...file, progress: progress, isShowPreview: isShowPreview, fileId: id, signedUrl: signedUrl }
                    : file,
            ),
        );
    };

    const removeFileByName = (fileId: string) => {
        setUploadedData((prevData) => prevData.filter((file) => file.fileId !== fileId));
    };

    const uploadResources = async () => {
        if (infoProps.handleConfirmButton && fileContent) {
            updatePropgress(60, false);
            const response = await infoProps.handleConfirmButton({
                content: fileContent,
                type: 'release-of-info',
            });
            console.log(response);
            if (response) {
                // Update progress to 100%
                const { fileId, signedUrl } = response?.data?.data;
                console.log(fileId);
                console.log(signedUrl);

                updatePropgress(100, false, fileId, signedUrl);
                setFileContent(null);
                setFileName('');
                updatePropgress(0, true, fileId, signedUrl);
            }
        }
    };

    const copyEistingResources = async () => {
        if (checkedIds.length > 0) {
            updatePropgress(60, false);
            const response = await infoProps.uploadResourceInfoForm({
                patientResourceIds: checkedIds,
                patientId: infoProps.patientId ?? '',
            });
            if (response) {
                if (infoProps.currentExistingRes) {
                    const selected = infoProps.currentExistingRes.filter(
                        (card) => card.resourceLink && response.data.data.includes(card.resourceLink),
                    );

                    const selectedModi = selected.map((items: ResourceChooseCardProps) => {
                        return {
                            ...items,
                            fileSize: '',
                            title: items.resourceName,
                            fileId: items.resourceLink,
                            dateCreated: items.uploadDate,
                            isShowPreview: true,
                        };
                    });

                    infoProps.updateCaseManagerInfo && infoProps.updateCaseManagerInfo(selectedModi);
                }
            }
        }
    };

    useEffect(() => {
        uploadResources();
    }, [fileContent]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={false}
            modalAdditionalClasses={'w-[600px] items-center justify-center flex-col'}
        >
            <Typhography component='div' additionalClasses='flex justify-between pt-4 px-4'>
                <Typhography component='div'>
                    <Heading
                        type='h2'
                        colorType='dark'
                        label={language.add.releaseInfo.modalHeader}
                        additionalClasses='mb-4'
                    />
                </Typhography>
                <Typhography component='div'>
                    <Icon iconType='closeModal' additionalClasses='' onClick={handleCancelButton} />
                </Typhography>
            </Typhography>
            <Typhography component='div' additionalClasses='border border-x-0 px-4'>
                <TabButton
                    options={filterOptions}
                    onClickTab={onClickTab}
                    setSelectedValues={setSelectedValues}
                    selectedValues={selectedValues}
                    additionalClasses='gap-6 my-4'
                />

                {selectedValues === 'upload' && (
                    <CustomFileInput
                        iconType='BlackUpload'
                        iconAlignment='left'
                        isIconEnabled={true}
                        buttonClasses={`rounded-md border-dashed border-2 my-4 p-6`}
                        buttonBgClass='bg-[#F1F5F9]'
                        textColor='text-black-540'
                        onFileChange={handleFileChange}
                        lableBtn='Upload Release of Information'
                    />
                )}
                {selectedValues === 'upload' && (
                    <UploadCardList
                        cardData={uploadedData}
                        setViewReleaseInfoPdf={infoProps.setViewReleaseInfoPdf}
                        setPdfReleaseInfoUrl={infoProps.setPdfReleaseInfoUrl}
                        removeFileByName={removeFileByName}
                        showPreview={infoProps.showPreview}
                    />
                )}
                {selectedValues === 'choose' && (
                    <ExistingCardList
                        cardData={infoProps.currentExistingRes}
                        showPreview={infoProps.showPreview}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                )}
            </Typhography>
            <Typhography component='div' additionalClasses='grid grid-cols-6 mt-4 pb-4 px-4'>
                <Typhography component='div' additionalClasses='col-start-1 col-span-1 justify-self-start'>
                    <Button
                        buttonLabel='Cancel'
                        data-testId='InputField-id2'
                        variants='primaryWhite'
                        onClick={handleCancelButton}
                    />
                </Typhography>

                <Typhography component='div' additionalClasses='col-start-5 col-span-2 flex justify-end space-x-4'>
                    <Button
                        buttonLabel='Create New'
                        data-testId='InputField-id2'
                        variants='primaryWhite'
                        onClick={() =>
                            infoProps.navigateToLetter && infoProps.navigateToLetter(infoProps.currentExcmgIndex)
                        }
                    />
                    <Button
                        buttonLabel='Add'
                        data-testId='InputField-id'
                        variants='primaryBlue'
                        additionalClasses='px-4'
                        onClick={() => {
                            if (selectedValues === 'choose') {
                                copyEistingResources();
                            } else {
                                infoProps.updateCaseManagerInfo && infoProps.updateCaseManagerInfo(uploadedData);
                            }
                        }}
                    />
                </Typhography>
            </Typhography>
        </Modal>
    );
};
