const RouteRegistry = {
    landing: {
        path: '/',
    },
    app: {
        paths: {
            root: {
                path: '/dashboard',
            },
        },
    },
    auth: {
        paths: {
            signIn: {
                path: '/auth/login',
            },
            reset: {
                path: '/auth/reset',
            },
            resetEmail: {
                path: '/auth/resetemail',
            },
            newPassword: {
                path: '/auth/newpassword',
            },
            signUp: {
                path: '/auth/signup',
            },
            verification: {
                path: '/auth/verify',
            },
            userDetails: {
                path: '/auth/userdetails',
            },
        },
    },
    patient: {
        paths: {
            list: {
                path: '/patient/list',
            },
            add: {
                path: '/patient/add',
            },
            edit: {
                path: '/patient/edit',
            },
            view: {
                path: '/patient/view',
            },
            screener: {
                path: '/patient/screener',
            },
            delete: {
                path: '/patient/delete',
            },
            letter: {
                path: '/patient/letter',
            },
        },
    },
    reporting: {
        paths: {
            list: {
                path: '/reporting',
            },
        },
    },
    error: {
        path: '/error',
    },
} as const;

export default RouteRegistry;
