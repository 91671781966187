import { ComponentType, useState } from 'react';
import { ApiResponse } from 'utils/types/api.type';
import { useReportingRepository } from 'repositories/reportingRepository';
import { IReportingHOCProps, IReportingRequestParams } from 'utils/types/reporting.type';

const ReportingHOC = <P extends IReportingHOCProps>(WrappedComponent: ComponentType<IReportingHOCProps>) => {
    const ReportingDataFetching: React.FC<P & IReportingHOCProps> = (props) => {
        const [isFetching, _setIsFetching] = useState(false);
        const reportingRepository = useReportingRepository();

        const getReporting = async (req: IReportingRequestParams): Promise<ApiResponse | undefined> => {
            try {
                _setIsFetching(true);
                const response = await reportingRepository.getReporting(req);
                _setIsFetching(false);
                return response;
            } catch (apiError) {
                _setIsFetching(false);
                return;
            }
        };

        const apiMethods = { getReporting };
        return <WrappedComponent {...props} {...apiMethods} isFetching={isFetching} />;
    };

    return ReportingDataFetching;
};

export default ReportingHOC;
