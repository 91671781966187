import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useImageRepository } from 'repositories/ImageRepository';
import { useUserRepository } from 'repositories/UserRepository';
import { useGlobalsRepository } from 'repositories/globalsRepository';
import { usePatientRepository } from 'repositories/patientRepository';
import { useScreeningRepository } from 'repositories/screeningRepository';
import RouteRegistry from 'routes/routeRegistry';
import { errorMsgInit } from 'utils/init';
import { IChallengeRequestParams, IListRequestParams, IResponse, ISchemaRequestParams } from 'utils/types/api.type';
import { IErrorMsg } from 'utils/types/auth.type';
import {
    ApiResponse,
    IExistingResourceRequest,
    IImageCreateRequest,
    IImageRequest,
    IPatientResourceRequest,
    IResourcesCreateRequest,
} from 'utils/types/image.type';
import {
    IPatient,
    IPatientAssign,
    IPatientProps,
    IPatientRequest,
    IServeyNoteRequest,
    ISolutionNoteRequest,
    ISolutionRequest,
    ITodoToggleRequestParams,
    PatientsFilter,
} from 'utils/types/patient.type';
import { ILetterRequest, ILetterRequestRInfo, INavigationProps, IScreenerRequest } from 'utils/types/screener.type';
import { ScreenerStatus } from 'utils/enum';
import { setScreenerData } from 'stores/screenerSlice';
import { RootState } from 'stores/store';
import { getNewScreenerState, getProgress } from './helpers/screenerHelper';
import { setSchema } from 'stores/schemaSlice';
import { get } from 'lodash';
import { setLetterFormSchemaData } from 'stores/letterFormSlice';

const patientHOC = <P extends IPatientProps>(WrappedComponent: ComponentType<IPatientProps>) => {
    const LoginDataFetching: React.FC<P & IPatientProps> = (props) => {
        const [isLoading, setIsLoading] = React.useState(false);
        const [errorMsg, setErrorMsg] = useState<IErrorMsg>(errorMsgInit);
        const [letterGenParams, setLetterGenParams] = useState<ILetterRequest>();
        const [schemaRequestParam, setSchemaRequestParam] = useState<ISchemaRequestParams>({ patientId: '' });

        const imageRepository = useImageRepository();
        const patientRepository = usePatientRepository();
        const userRepository = useUserRepository();
        const globalsRepository = useGlobalsRepository();
        const screeningRepository = useScreeningRepository();
        const navigate = useNavigate();
        const { patientId } = useParams();
        const dispatch = useDispatch();
        globalsRepository.getPrimaryLanguages();
        globalsRepository.getRaces();
        globalsRepository.getEducations();
        globalsRepository.getGenders();
        globalsRepository.getHousingStatuses();
        globalsRepository.getMailingAddresses();
        globalsRepository.getResourceTypes();
        const { data: newSchema } = screeningRepository.getSchema();
        const { error: usersError, isLoading: usersIsloading } = userRepository.getUsers();
        const {
            data: patientSchemaData,
            isFetching: patientSchemaIsloading,
            refetch: patientSchemaRefetch,
        } = screeningRepository.getPatientSchema(schemaRequestParam);
        const screenerData = useSelector((state: RootState) => state.screener.data);

        // Fetching Schema
        useEffect(() => {
            if (newSchema?.data && newSchema?.data.schemaSteps?.length > 0) {
                dispatch(setSchema(newSchema?.data));
            }
        }, [newSchema]);

        // Fetching Patient Schema
        useMemo(() => {
            if (patientId) setSchemaRequestParam({ patientId: patientId });
        }, [patientId]);

        useMemo(() => {
            if (patientId && patientSchemaData?.data && !patientSchemaIsloading && screenerData) {
                const status: ScreenerStatus =
                    getProgress(patientSchemaData.data) > 0 ? ScreenerStatus.INPROGRESS : ScreenerStatus.NOTSTARTED;
                const newState = getNewScreenerState(patientId, patientSchemaData.data, status, screenerData);
                if (newState.length > 0) dispatch(setScreenerData(newState));
            }
        }, [patientSchemaData, patientSchemaIsloading]);

        // Fetching Data
        useEffect(() => {
            const fetchUsers = async () => {
                try {
                    if (usersIsloading) {
                        return;
                    }
                    if (usersError) {
                        setErrorMsg({
                            isVisible: true,
                            type: 'danger',
                            msg: 'Patients loading failed.',
                        });
                        return;
                    }
                } catch (error) {
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Patients loading failed.',
                    });
                    return;
                }
            };

            fetchUsers();
        }, []);

        const navigateToPatients = (isSaved: boolean) => {
            navigate(`${get(RouteRegistry, 'patient.paths.list.path')}?filter=${PatientsFilter.MY}`, {
                state: { isSaved: isSaved },
            });
        };

        const navigateToEdit = (patientId: string) => {
            navigate(`${get(RouteRegistry, 'patient.paths.edit.path')}/${patientId}`);
        };

        const navigateToView = (
            patientId: string,
            refetch?: boolean,
            isEnableSolution: boolean = false,
            letterData?: any,
        ) => {
            navigate(`${get(RouteRegistry, 'patient.paths.view.path')}/${patientId}`, {
                state: {
                    refetch: refetch ? refetch : false,
                    isEnableSolution: isEnableSolution,
                    letterBackActionData: letterData,
                },
            });
        };

        const navigateToAdd = (patientId: string, refetch?: boolean, caseMngerIndex?: boolean, fileId?: string) => {
            navigate(`${get(RouteRegistry, 'patient.paths.edit.path')}/${patientId}`, {
                state: {
                    refetch: refetch ? refetch : false,
                    caseMngerIndex: caseMngerIndex,
                    fileId: fileId,
                },
            });
        };

        const navigateToDelete = (id: string, name: string) => {
            navigate(get(RouteRegistry, 'patient.paths.delete.path'), { state: { id: id, name: name } });
        };

        const navigateToLetterView = (props: INavigationProps) => {
            navigate(get(RouteRegistry, 'patient.paths.letter.path'), {
                state: props,
            });
        };
        // Save Image Function
        const saveImage = async (req: IImageCreateRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await imageRepository.saveImage(req);
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Saving image failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // Delete Image Function
        const deleteImage = async (req: IImageRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await imageRepository.deleteImage(req);
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Delete image failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // Get Image Function
        const getImage = (
            req: IImageRequest,
        ): {
            data: ApiResponse | undefined;
            error: FetchBaseQueryError | SerializedError | undefined;
            isLoading: boolean;
        } => {
            return imageRepository.getImage(req);
        };

        // Get Image Function
        const screenerVersions = (
            req: ISchemaRequestParams,
        ): {
            data: ApiResponse | undefined;
            error: FetchBaseQueryError | SerializedError | undefined;
            isLoading: boolean;
        } => {
            return screeningRepository.screenerVersions(req);
        };

        // Get Image Function
        const getPatientSchemaVersion = (
            req: ISchemaRequestParams,
        ): {
            data: ApiResponse | undefined;
            error: FetchBaseQueryError | SerializedError | undefined;
            isFetching: boolean;
        } => {
            return screeningRepository.getPatientSchema(req);
        };

        const getPatientLetterSchemaVersion = async (
            req: ISchemaRequestParams,
        ): Promise<{
            data: ApiResponse | undefined;
            error: FetchBaseQueryError | SerializedError | undefined;
            isFetching: boolean;
        }> => {
            const response = await screeningRepository.getPatientLetterSchema(req);
            if (response)
                dispatch(setLetterFormSchemaData({ schema: response?.data?.data.form, patientId: req.patientId }));

            return response;
        };

        const getPatientLetterSchemaReleaseInfo = async (
            req: string,
        ): Promise<{
            data: ApiResponse | undefined;
            error: FetchBaseQueryError | SerializedError | undefined;
            isFetching: boolean;
        }> => {
            setIsLoading(true);
            const response = await screeningRepository.getPatientLetterSchemaReleaseInfo(req);
            if (response) dispatch(setLetterFormSchemaData({ schema: response?.data?.data.form, patientId: req }));
            setIsLoading(false);
            return response;
        };

        // Add patient
        const createPatient = async (req: IPatient): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.addPatient(req);
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Saving patient failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // Edit patient
        const editPatient = async (req: IPatient): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.editPatient(req);
                if (result.error) {
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Edit patient failed.',
                    });
                }
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // Edit patient Assign
        const editAssign = async (req: IPatientAssign): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.editAssign(req);
                if (result.error) {
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Edit patient failed.',
                    });
                }
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // Edit patient Assign
        const editAssignBulk = async (req: IPatientAssign): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.editAssignBulk(req);
                if (result.error) {
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Edit patient failed.',
                    });
                }
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // Delete patient
        const deletePatient = async (req: IPatientRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.deletePatient(req);
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Delete patient failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // get patient details
        const getPatientList = (
            gridProps: IListRequestParams,
            filter: PatientsFilter,
        ): {
            data: ApiResponse | undefined;
            error: FetchBaseQueryError | SerializedError | undefined;
            isFetching: boolean;
            refetch: () => void;
        } => {
            switch (filter) {
                case PatientsFilter.ASSIGNED:
                    return patientRepository.getAssignedPatientList(gridProps);
                case PatientsFilter.UNASSIGNED:
                    return patientRepository.getUnassignedPatientList(gridProps);
                case PatientsFilter.RI:
                    return patientRepository.getPatientListForRI(gridProps);
                default:
                    return patientRepository.getMyPatientList(gridProps);
            }
        };

        // Get patient
        const getPatient = (
            req: IPatientRequest,
        ): {
            data: ApiResponse | undefined;
            error: FetchBaseQueryError | SerializedError | undefined;
            isFetching: boolean;
            refetch: () => void;
        } => {
            return patientRepository.getPatient(req);
        };

        // Update Screener
        const updateScreenerSteps = async (req: IScreenerRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await screeningRepository.updateScreenerStep(req);
                if (result.error) {
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Saving patient failed.',
                    });
                } else {
                    patientSchemaRefetch();
                }
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        //update letter schema
        const updateLetterSchemaValues = async (req: ILetterRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await screeningRepository.updateLetterSchemaValues(req);
                if (result.error) {
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Saving patient failed.',
                    });
                }
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };
        //update letter schema
        const updateLetterSchemaValuesForRInfo = async (req: ILetterRequestRInfo): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await screeningRepository.updateLetterSchemaValuesForRInfo(req);
                if (result.error) {
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Saving patient failed.',
                    });
                }
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // get challenge details
        const getChallenges = async (challgeProps: IChallengeRequestParams): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.getChallengeUnresolvedList(challgeProps);
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        const getSolutionRowData = async (solutionProps: ISolutionRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.getSolutionRowData(solutionProps);

                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        const getFile = (req: string, uploadType: string): IResponse => {
            const data = patientRepository.getFile(req, uploadType);
            return data;
        };

        const getResources = (resourceProps: string): IResponse => {
            const data = patientRepository.getResources(resourceProps);
            return data;
        };
        // update patient challenges
        const makeChallengeUrgent = async (req: any): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.makeUrgent(req);
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Delete patient failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };
        
        // update patient challenges
        const saveChallengeNotes = async (req: any): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.makeUrgent({ ...req, note: req.note, shouldUpdateLastContactDate: req.note.shouldUpdateLastContactDate });
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Save challenge note failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // update patient challenges
        const savePatientResource = async (req: IPatientResourceRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.savePatientResources(req);
                if (result.error) setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        const editPatientResource = async (req: IPatientResourceRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.editPatientResources(req);
                if (result.error) setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // update patient challenges
        const saveSolutionNote = async (req: ISolutionNoteRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.saveSolutionNote(req);
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Save note failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        const saveServeyNotes = async (req: IServeyNoteRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.saveServeyNotes(req);
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Save note failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        const updateServeyNotes = async (req: IServeyNoteRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.updateServeyNotes(req);
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Save note failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        const updateNotes = async (req: IServeyNoteRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.updateNotes(req);
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Save note failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        const toggleTodoCheckbox = async (req: ITodoToggleRequestParams): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.toggleTodoCheckbox(req);
                if (result.error) {
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Checkbox toggling failed.'
                    });
                }
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        }

        //get timeline data
        const getPatinentTimeLine = (req: string): IResponse => {
            const data = patientRepository.getPatientTimeLine(req);
            return data;
        };

        //get challenge timeline data
        const getPatientChallengeTimeLine = (patientId: string, challengeId: string): IResponse => {
            const data = patientRepository.getPatientChallengeTimeLine(patientId, challengeId);
            return data;
        };

        const getNoteHistory = async (
            req: string,
        ): Promise<{
            data: ApiResponse | undefined;
            error: FetchBaseQueryError | SerializedError | undefined;
            isFetching: boolean;
        }> => {
            const response = await patientRepository.getNoteHistory(req);
            return response;
        };

        const uploadResourceInfo = async (req: IImageCreateRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.uploadResourceInfo(req);
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Saving image failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        const deleteResourceInfo = async (req: string): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.deleteResourceInfo(req);
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Saving image failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };
        const getReleaseInfoFiles = async (req: IResourcesCreateRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.getReleaseInfoFiles(req);
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Saving image failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        const uploadResourceInfoForm = async (req: IExistingResourceRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const result = await patientRepository.uploadResourceInfoForm(req);
                if (result.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Saving image failed.',
                    });
                setIsLoading(false);
                return result;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        const apiMethods = {
            saveImage,
            deleteImage,
            getImage,
            createPatient,
            editPatient,
            getPatientList,
            deletePatient,
            getPatient,
            updateScreenerSteps,
            editAssign,
            editAssignBulk,
            screenerVersions,
            getPatientSchemaVersion,
            getChallenges,
            makeChallengeUrgent,
            saveChallengeNotes,
            getSolutionRowData,
            saveSolutionNote,
            savePatientResource,
            editPatientResource,
            getResources,
            getFile,
            getPatientLetterSchemaVersion,
            updateLetterSchemaValues,
            getPatinentTimeLine,
            getPatientChallengeTimeLine,
            saveServeyNotes,
            updateServeyNotes,
            updateNotes,
            toggleTodoCheckbox,
            getNoteHistory,
            setIsLoading,
            letterGenParams,
            setLetterGenParams,
            uploadResourceInfo,
            deleteResourceInfo,
            getReleaseInfoFiles,
            getPatientLetterSchemaReleaseInfo,
            navigateToAdd,
            updateLetterSchemaValuesForRInfo,
            uploadResourceInfoForm,
            patientSchemaRefetch
        };

        return (
            <WrappedComponent
                {...(props as P & IPatientProps)}
                {...apiMethods}
                navigateToPatients={navigateToPatients}
                navigateToEdit={navigateToEdit}
                navigateToDelete={navigateToDelete}
                navigateToView={navigateToView}
                navigateToLetterView={navigateToLetterView}
                isLoading={isLoading}
                errorMsg={errorMsg}
            />
        );
    };

    return LoginDataFetching;
};

export default patientHOC;
