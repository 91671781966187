import { CloseModalIcon, FileIcon } from 'containers/patientHOC/helpers/iconHelper';
import { Button } from 'dfh-ui-library';
import moment from 'moment';
import React from 'react';
import { CardListProps, ResourceCardProps } from 'utils/types/patient.type';

const Card: React.FC<ResourceCardProps> = ({
    fileSize,
    progress,
    dateCreated,
    isShowPreview,
    removeFileByName,
    fileId,
    showPreview,
}) => {
    return (
        <div className='border rounded-lg p-4 mb-4 bg-[#F9FAFB]'>
            <div className='flex justify-between items-center gap-2'>
                <div className='flex items-center justify-between'>
                    <div className='flex items-center'>
                        <div className='p-2 rounded-lg mr-4'>
                            <FileIcon />
                        </div>
                        <div>
                            <h2 className='text-21s font-medium'>
                                {`Release of Information ${moment(dateCreated).format('MM/DD/YYYY')}`}
                            </h2>
                            <p className='text-12s text-gray-500 font-normal'>
                                {fileSize}
                                {progress !== undefined && !isShowPreview && `• ${progress}% • 2 sec left`}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex justify-end items-center gap-4'>
                    {isShowPreview && (
                        <Button
                            buttonLabel='Preview'
                            data-testId='InputField-id'
                            iconAlignment='left'
                            iconType='PreviewIcon'
                            isIconEnabled
                            variants='primaryWhite'
                            onClick={() => showPreview && fileId && showPreview(fileId)}
                        />
                    )}
                    <CloseModalIcon
                        onClick={() => removeFileByName && fileId && removeFileByName(fileId)}
                        w={'16'}
                        h={'16'}
                        classes=''
                    />
                </div>
            </div>

            {progress !== undefined && !isShowPreview && (
                <div className='mt-2 ml-2'>
                    <div className='w-full bg-gray-200 rounded-full h-1.5'>
                        <div className='bg-blue-600 h-1.5 rounded-full' style={{ width: `${progress}%` }}></div>
                    </div>
                </div>
            )}
        </div>
    );
};

const UploadCardList: React.FC<CardListProps> = ({ cardData, ...props }) => {
    return (
        <div className=''>
            {cardData.map((card, index) => (
                <Card
                    key={index}
                    title={card.title}
                    fileSize={card.fileSize}
                    progress={card.progress}
                    dateCreated={card.dateCreated}
                    isShowPreview={card.isShowPreview}
                    setViewReleaseInfoPdf={props.setViewReleaseInfoPdf}
                    setPdfReleaseInfoUrl={props.setPdfReleaseInfoUrl}
                    signedUrl={card.signedUrl}
                    removeFileByName={props.removeFileByName}
                    showPreview={props.showPreview}
                    fileId={card.fileId}
                />
            ))}
        </div>
    );
};

export default UploadCardList;
