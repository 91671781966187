// authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    isAuthenticated: boolean;
    email: string;
    isEmailVerified: boolean;
    name: string;
}

const initialState: AuthState = {
    isAuthenticated: false,
    email: '',
    isEmailVerified: false,
    name: '',
};

const authSlice = createSlice({
    name: '/authApi',
    initialState,
    reducers: {
        signIn: (state, action: PayloadAction<any>) => {
            state.isAuthenticated = true;
            state.email = action.payload.email;
        },
        signOut: (state) => {
            state.isAuthenticated = false;
        },
    },
});

export const { signIn, signOut } = authSlice.actions;
export const selectAuth = (state: { auth: AuthState }) => state.auth;

export default authSlice.reducer;
