import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { patientAPI } from 'api/patientAPI';
import { IPatient } from 'utils/types/patient.type';

type InitialState = {
    data: { results: IPatient[]; total: number };
    isLoading: boolean;
    error: null | string;
};

const patientSlice = createSlice({
    name: '/patientApi',
    initialState: {
        data: { results: [], total: 0 },
        isLoading: false,
        error: null,
    } as InitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(isAnyOf(patientAPI.endpoints.patientList.matchFulfilled), (state, action) => {
                state.data = action.payload.data;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(patientAPI.endpoints.patientList.matchPending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(patientAPI.endpoints.patientList.matchRejected), (state, action) => {
                if (action.error.message) state.error = action.error.message;
                state.isLoading = false;
            });
    },
});

export default patientSlice.reducer;
