import {
    Button,
    ButtonGroupMultiSelect,
    HistoryDetails,
    Input,
    NoteEditor,
    NoteHistoryProps,
    NoteProps,
    Timeline,
    TimelineEventProps,
    Typhography,
} from 'dfh-ui-library';
import React, { useEffect, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { TIMELINE_ACTION_TYPES, TIMELINE_TYPES } from 'utils/enum';
import { LocalStorageService } from 'utils/storage/LocalStorageService';
import { ITimelineProps } from 'utils/types/patient.type';

const TimelineAndHistory: React.FC<ITimelineProps> = ({
    timeLineData,
    saveServeyNotes,
    navigationItemAction,
    getNoteHistory,
    toggleTodoCheckbox
}) => {
    const localStorageService = LocalStorageService();
    const [noteMessage, setNoteMessage] = useState('');
    const [showNote, setShowNote] = useState(false);
    //const [showAllNote, showAllNotes] = useState(false);
    const [showAll, setShowAll] = useState(true);
    const [filteredTimeLine, setFilteredTimeLine] = useState<TimelineEventProps[]>(timeLineData);
    const [showFilteredTimeLineTodos, setShowFilteredTimeLineTodos] = useState(false);
    const [showNoteHistory, setShowNoteHistory] = useState(false);
    const [noteHistories, setNoteHistories] = useState<NoteHistoryProps>();

    const [selectedTab, setSelectedTab] = useState<number[]>(() => {
        const savedTabs = localStorageService.getSelectedTimelineTabs();
        return savedTabs ? JSON.parse(savedTabs) : []
    });    
    useEffect(() => {
        selectedTab && getSelectedTab(selectedTab);
    }, [timeLineData]);

    useEffect(() => {
        let filteredTimeLine: TimelineEventProps[] = getFilteredTimeline(timeLineData, selectedTab);
        setFilteredTimeLine(filteredTimeLine);
    }, [showFilteredTimeLineTodos]);

    useEffect(() => {
        localStorageService.setSelectedTimelineTabs(selectedTab);
        getSelectedTab(selectedTab);
    }, [selectedTab]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNoteMessage(e.target.value);
    };

    const viewNoteHistory = async (show: boolean, noteId?: string, eventData?: NoteHistoryProps) => {
        const response = noteId && (await getNoteHistory(noteId));
        if (response && eventData) {
            const histories: NoteHistoryProps = {
                editedBy: eventData?.editedBy,
                dateTime: eventData?.dateTime,
                notesHistory: response.data ? response.data?.data : [],
                type: eventData?.type,
                solutions: eventData?.solutions,
                challenge: eventData?.challenge,
                challengeResolved: eventData?.challengeResolved,
            };
            setNoteHistories(histories);
            setShowNoteHistory(show);
        }
    };
    const getSelectedType = (selectedTabs: TIMELINE_ACTION_TYPES[]) => {
        return selectedTabs.map((tab) => TIMELINE_TYPES[tab] as string);
    };

    const getSelectedTab = (selected: TIMELINE_ACTION_TYPES[]) => {
        let filteredTimeLine: TimelineEventProps[] = getFilteredTimeline(timeLineData, selected);
        setFilteredTimeLine(filteredTimeLine)
        setSelectedTab(selected);
    };

    const getFilteredTimeline= (timeLineData: TimelineEventProps[], selectedTabs: number[]) => {
        // Ensuring that the key exists in TIMELINE_TYPES to avoid undefined errors
        const selectedTypes = getSelectedType(selectedTabs);        
        
        let filteredTimeLine: TimelineEventProps[];

        // Check if the selectedType is undefined before filtering to avoid runtime errors
        if (selectedTypes && selectedTypes.length > 0) {
            filteredTimeLine = timeLineData.filter((value: any) => selectedTypes.includes(value.type));
        } else if (selectedTypes.length === 0) {
            filteredTimeLine = timeLineData;
        } else {
            filteredTimeLine = [];
        }

        if (showFilteredTimeLineTodos) {
            filteredTimeLine = filteredTimeLine.filter(item => 
                item.note && item?.note?.note?.some(n => n.enableCheckbox === true && n.isChecked === false)
            );
        }
        
        return filteredTimeLine;
    };

    const handleAddItem = async (_items: NoteProps) => {
        const response = _items.note && (await saveServeyNotes({ note: _items, noteType: 'Normal' }));
        if (response) {
            setShowNote(false);
            getSelectedTab([TIMELINE_ACTION_TYPES.Normal]);
        }
    };

    const handleEditItem = async (_items: any, serveyId?: string, _type?: string) => {
        const response = await saveServeyNotes({ note: _items, survey: serveyId ?? '', noteType: 'Survey' });
        if (response) {
            setShowNote(false);
        }
        return response;
    };

    const getToDoCount = () => {
        const filteredNotes = timeLineData.flatMap((value) => {
            if (Array.isArray(value.note?.note)) {
                return value.note?.note.filter(
                    (filteredNote: any) => filteredNote?.enableCheckbox === true && filteredNote?.isChecked === false,
                );
            }
            return [];
        });
        return filteredNotes.length;
    };

    return (
        <>
            <section className=' w-1/4 xl:w-1/4 min-w-80 mt-3 lg:mt-0 bg-white rounded-md border' style={{ height: 'calc(100vh - 146px)' }}>
                <Typhography component='div' additionalClasses='border-b '>
                    {!showNote ? (
                        <Typhography
                            component='div'
                            additionalClasses='flex justify-between align-middle p-4 text-nowrap gap-2'
                        >
                            <Input
                                name='noteMessage'
                                value={noteMessage}
                                onChange={handleChange}
                                isAdditionalErrorInput={true}
                                placeholder='New note...'
                                labelType='formLabelMedeum'
                                inputVariant='small'
                                inputFocus={() => setShowNote(true)}
                            />

                            <Button
                                bagageProps={{
                                    bgColor: 'bg-[#006CD0]',
                                    text: getToDoCount().toString(),
                                }}
                                buttonLabel='To-Dos'
                                data-testId='InputField-id'
                                enableBagage
                                iconAlignment='left'
                                iconType='ToDoCheck'
                                isIconEnabled
                                variants={showFilteredTimeLineTodos ? 'smallWhiteSelected' : 'smallWhite'}
                                onClick={() => {
                                    setShowFilteredTimeLineTodos(!showFilteredTimeLineTodos);
                                }}
                            />

                            <div data-tooltip-id='colapseAllNotes'>
                                <Button
                                    data-testId='InputField-id'
                                    iconAlignment='left'
                                    iconClass='px-1'
                                    iconType={`${!showAll ? 'DownDouble' : 'UpDouble'}`}
                                    isIconEnabled
                                    variants='smallWhite'
                                    onClick={() => {
                                        // showAllNotes(!showAllNote);
                                        setShowAll(!showAll);
                                    }}
                                />
                            </div>
                        </Typhography>
                    ) : (
                        <Typhography component='div' additionalClasses='justify-between align-middle py-4 pr-4'>
                            <NoteEditor
                                onAdd={handleAddItem}
                                cancelAction={() => setShowNote(false)}
                                showNote={showNote}
                                additionalClassesNote='pl-4 h-full overflow-y-auto'
                                additionalClassesButtons='pl-4'
                            />
                        </Typhography>
                    )}
                </Typhography>
                <Typhography component='div'>
                    <Typhography additionalClasses='p-4' component='div'>
                        {showNoteHistory ? (
                            <Typhography component='div' additionalClasses='flex divide-x gap-2 items-center'>
                                <Button
                                    additionalClasses='text-12s font-medium justify-center text-[#006CD0] bg-inherit !pl-1 !py-0 !h-full'
                                    buttonLabel='Back'
                                    data-testId='InputField-id'
                                    iconAlignment='left'
                                    variants='primaryNoBoader'
                                    iconType='BlackPrevIcon'
                                    iconColor='#006CD0'
                                    isIconEnabled={true}
                                    onClick={() => setShowNoteHistory(false)}
                                />
                                <Typhography additionalClasses='font-semibold text-25s pl-4'>Edit history</Typhography>
                            </Typhography>
                        ) : (
                            <Typhography additionalClasses='' component='div'>
                                <ButtonGroupMultiSelect
                                    onSelect={getSelectedTab}
                                    options={[
                                        {
                                            id: 1,
                                            value: 'Challenge',
                                        },
                                        {
                                            id: 2,
                                            value: 'Solution',
                                        },
                                        {
                                            id: 3,
                                            value: 'Notes',
                                        },
                                        {
                                            id: 4,
                                            value: 'Screener',
                                        },
                                    ]}
                                    selected={selectedTab}
                                    buttonType={'small'}
                                    buttonGroupCol={'grid-cols-4'}
                                    additionalClasses=''
                                />
                            </Typhography>
                        )}
                    </Typhography>
                    <div
                        style={{ marginLeft: '-12px', height: `calc(100vh - ${showNote ? '400px' : '292px'})` }}
                        className='overflow-hidden overflow-y-auto'
                    >
                        {!showNoteHistory ? (
                            <>
                                {filteredTimeLine.length > 0 ? (
                                    <Timeline
                                        timelines={filteredTimeLine}
                                        showAll={showAll}
                                        handleEditItem={handleEditItem}
                                        viewNoteHistory={viewNoteHistory}
                                        navigationAction={navigationItemAction}
                                        type={getSelectedType(selectedTab)}
                                        toggleTodoCheckbox={toggleTodoCheckbox}
                                    />
                                ) : (
                                    <Typhography component='div' additionalClasses='flex justify-center text-14s'>
                                        No Results.
                                    </Typhography>
                                )}
                            </>
                        ) : (
                            noteHistories && <HistoryDetails {...noteHistories} />
                        )}
                    </div>
                </Typhography>
            </section>

            <ReactTooltip
                id='colapseAllNotes'
                data-tooltip-place='top-end'
                content={`${showAll ? 'Collapse all notes' : 'Show all notes'}`}
                style={{ backgroundColor: '#21232C', fontSize: '12' }}
                data-tooltip-class-name={'text-red text-12s'}
                place='top-end'
            />
        </>
    );
};

export default TimelineAndHistory;
