import { ApiResponse } from 'utils/types/api.type';
import { IGlobalsRepository } from './IGlobalsRepository';
import {
    useGetEducationQuery,
    useGetGenderQuery,
    useGetHousingStatusQuery,
    useGetMailingAddressQuery,
    useGetPrimaryLanguageQuery,
    useGetRaceQuery,
    useGetResourceTypesQuery,
} from 'api/globalsAPI';

export const useGlobalsRepository = (): IGlobalsRepository => {
    const { data: primaryLanguageData } = useGetPrimaryLanguageQuery({});
    const { data: raceData } = useGetRaceQuery({});
    const { data: educationData } = useGetEducationQuery({});
    const { data: genderData } = useGetGenderQuery({});
    const { data: housingStatusData } = useGetHousingStatusQuery({});
    const { data: mailingAddressData } = useGetMailingAddressQuery({});
    const { data: resourceTypesData } = useGetResourceTypesQuery({});

    const getPrimaryLanguages = (): Promise<ApiResponse> => {
        return primaryLanguageData;
    };

    const getRaces = (): Promise<ApiResponse> => {
        return raceData;
    };

    const getEducations = (): Promise<ApiResponse> => {
        return educationData;
    };

    const getGenders = (): Promise<ApiResponse> => {
        return genderData;
    };

    const getHousingStatuses = (): Promise<ApiResponse> => {
        return housingStatusData;
    };

    const getMailingAddresses = (): Promise<ApiResponse> => {
        return mailingAddressData;
    };

    const getResourceTypes = (): Promise<ApiResponse> => {
        return resourceTypesData;
    };
    return {
        getPrimaryLanguages,
        getRaces,
        getEducations,
        getGenders,
        getHousingStatuses,
        getMailingAddresses,
        getResourceTypes,
    };
};
