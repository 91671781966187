import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiResponse } from '../utils/types/api.type';
import { baseQueryWithReAuth } from './interceptors';
import { IImageCreateRequest, IImageRequest } from 'utils/types/image.type';

const baseQuery = baseQueryWithReAuth;

export const imageAPI = createApi({
    reducerPath: '/imageApi',
    baseQuery,
    endpoints: (builder) => ({
        imageAdd: builder.mutation<ApiResponse, IImageCreateRequest>({
            query: (data) => ({
                url: '/images',
                method: 'POST',
                body: data,
            }),
        }),
        imageDelete: builder.mutation<ApiResponse, IImageRequest>({
            query: (data) => ({
                url: '/images',
                method: 'DELETE',
                body: data,
            }),
        }),
        image: builder.query<ApiResponse, IImageRequest>({
            query: (data) => ({
                url: `/images?imageId=${data.imageId}&type=${data.type}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useImageAddMutation, useImageDeleteMutation, useImageQuery } = imageAPI;
