import { Button, IconInput, LoadingSpinner, Message, Typhography } from 'dfh-ui-library';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ILoginProps, passwordArgProps } from 'utils/types/auth.type';
import { inputTypeInit } from 'utils/init';
import loginHOC from '../index';

const Login = (props: ILoginProps) => {
    const [passwordArgs, setPasswordArgs] = useState<passwordArgProps>(inputTypeInit);

    // Login form config
    const formik = useFormik({
        initialValues: {
            password: '',
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Email is required'),
            password: Yup.string().required('Password is required'),
        }),
        onSubmit: async (values) => {
            const loginResult = await props.apiMethods.login({
                email: values.email,
                password: values.password,
            });
            if (loginResult && loginResult.data) {
                formik.resetForm();
                if (!loginResult.data.data.payload.email) {
                    // change to email_verified
                    props.navigateMethods.navigateToVerification(loginResult.data.data.payload.email);
                } else if (loginResult.data.data.payload?.fullName === undefined) {
                    props.navigateMethods.navigateToUserDetails(loginResult.data.data.payload.email);
                } else {
                    props.navigateMethods.navigateToHome();
                }
            }
        },
    });

    // Show/Hide password
    const showHidePassword = () => {
        if (passwordArgs.type === 'password') {
            setPasswordArgs({
                type: 'text',
                rightIcon: 'EyePswOff',
            });
        } else {
            setPasswordArgs({
                type: 'password',
                rightIcon: 'EyePsw',
            });
        }
    };

    return (
        <>
            {props.isLoading && <LoadingSpinner />}
            {props.errorMsg.isVisible && <Message type={props.errorMsg.type} text={props.errorMsg.msg} />}
            <Typhography type='authHeader'>Login</Typhography>
            <form onSubmit={formik.handleSubmit}>
                <Typhography component='div' additionalClasses='grid grid-cols-1 mt-4'>
                    <Typhography component='div' additionalClasses='py-2'>
                        <Typhography component='div'>
                            <IconInput
                                iconClassLeft='top-4 left-4'
                                isIconLeft
                                leftIcon='EmailLight'
                                label='Email'
                                placeholder='example@mail.com'
                                name='email'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                                isAdditionalErrorInput={true}
                                additionalErrorClasses='text-red-500 text-xs pt-1'
                                additionalClasses={formik.errors.email ? 'border-red-500' : ''}
                                isBorderedError={formik.errors.email ? true : false}
                            />
                        </Typhography>
                    </Typhography>
                    <Typhography component='div' additionalClasses='py-2'>
                        <Typhography>
                            <Typhography additionalClasses='flex flex-col relative'>
                                <Typhography
                                    type='linkTextNormal'
                                    additionalClasses='cursor-pointer ml-1 absolute right-1 top-1 text-12s text-btn-main font-medium'
                                    onClick={props.navigateMethods.navigateToResetPassword}
                                >
                                    Forgot your password?
                                </Typhography>
                                <IconInput
                                    iconClassLeft='top-4 left-4 absolute'
                                    iconClassRight='right-0 right-1.5 top-4'
                                    iconOnClick={showHidePassword}
                                    isIconLeft
                                    isIconRight
                                    leftIcon='keyIcon'
                                    type={passwordArgs.type}
                                    rightIcon={passwordArgs.rightIcon}
                                    label='Password'
                                    placeholder='*******'
                                    name='password'
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.password && formik.errors.password ? formik.errors.password : ''
                                    }
                                    isAdditionalErrorInput={true}
                                    additionalErrorClasses='text-red-500 text-xs pt-1'
                                    additionalClasses={formik.errors.password ? 'border-red-500' : ''}
                                    isBorderedError={formik.errors.password ? true : false}
                                />
                            </Typhography>
                        </Typhography>
                    </Typhography>

                    <Typhography additionalClasses='my-8'>
                        <Button
                            type='submit'
                            buttonLabel='Login'
                            variants='primaryBlue'
                            additionalClasses='bg-[#006CD0]'
                            isDisabled={!(formik.isValid && formik.dirty)}
                        ></Button>
                    </Typhography>
                    <Typhography additionalClasses='py-2 text-center'>
                        <Typhography component='p'>
                            <Typhography type='textMediumGray' additionalClasses='text-gray-400'>
                                Don't have an account?
                            </Typhography>

                            <Typhography
                                type='linkTextMedium'
                                additionalClasses='cursor-pointer ml-1'
                                onClick={props.navigateMethods.navigateToSignUp}
                            >
                                Sign Up
                            </Typhography>
                        </Typhography>
                    </Typhography>
                </Typhography>
            </form>
        </>
    );
};

const LoginPageComponent = loginHOC(Login);
export default LoginPageComponent;
