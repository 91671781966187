import { CloseIcon, LargeCheckGreen, UndoIcon } from '../helpers/iconHelper';

interface NotificationProps {
    headerTitle?: string;
    message?: string;
    type?: 'success' | 'error' | 'info';
    count?: number;
    handleUndo?: () => void;
    handleCancel?: () => void;
    description?: string;
    isBasic?: boolean;
}

const GridNotification: React.FC<NotificationProps> = ({
    headerTitle = 'Patient were assigned a responsible use',
    count,
    handleUndo,
    handleCancel,
    description = 'These patients will disappear from the table the nextime you open this table',
    isBasic = false,
}) => {
    return (
        <div className={`fixed bottom-0 w-full ${isBasic && 'justify-center flex'}`}>
            {/* {Ntprops.message} */}
            <div className='container z-50 items-center'>
                <div className='lg:max-w-4xl mx-auto'>
                    {isBasic ? (
                        <div className='rounded-md grid grid-cols-10  shadow-[0_4px_20px_-0px_#21232C14] bg-white w-3/4'>
                            <div className='border-b-4 border-green-600  rounded-bl col-span-7 flex content-center justify-start'>
                                <div className='flex gap-2 items-center pl-2 py-4'>
                                    <div className='w-1/6 py-3 flex justify-center'>
                                        <LargeCheckGreen />
                                    </div>
                                    <div className='font-semibold text-black-540 text-25s text-nowrap'>
                                        {count} {headerTitle}
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-3 items-center flex justify-end'>
                                <div className='px-6 flex'>
                                    <button
                                        className='bg-white border px-4 py-2 rounded-lg text-gray-900 text-sm flex'
                                        onClick={handleUndo}
                                    >
                                        <UndoIcon classes='mr-1' />
                                        Undo
                                    </button>
                                    <button className=' py-1 px-3 rounded-sm' onClick={handleCancel}>
                                        <CloseIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='rounded-md grid grid-cols-10  shadow-[0_4px_20px_-0px_#21232C14] bg-white'>
                            <div className='border-b-4 border-green-600  rounded-bl col-span-7 flex content-center justify-start'>
                                <div className='py-4 flex'>
                                    <div className='w-1/6 py-3 flex justify-center'>
                                        <LargeCheckGreen />
                                    </div>
                                    <div>
                                        <h5 className='font-semibold text-black-540 text-25s'>
                                            {count} {headerTitle}
                                        </h5>
                                        <p className='text-21s text-grey-540'>{description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-3 items-center flex justify-end'>
                                <div className='px-6 flex'>
                                    <button
                                        className='bg-white border px-4 py-2 rounded-lg text-gray-900 text-sm flex'
                                        onClick={handleUndo}
                                    >
                                        <UndoIcon classes='mr-1' />
                                        Undo
                                    </button>
                                    <button className=' py-1 px-3 rounded-sm' onClick={handleCancel}>
                                        <CloseIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default GridNotification;
