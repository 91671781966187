import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import '../../../styles/PdfViewer.css';

import type { PDFDocumentProxy } from 'pdfjs-dist';
import LoadingLarge from 'components/Loading/LodingLarge';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

type PDFFile = string | File | null;

const PdfViewer: React.FC<{ fileUrl: PDFFile }> = ({ fileUrl }) => {
    const [numPages, setNumPages] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
        setIsLoading(false);
        setNumPages(nextNumPages);
    }

    return (
        <div className='Example h-full'>
            {isLoading && (
                <div className='relative flex h-full w-full justify-center items-center'>
                    <LoadingLarge />
                </div>
            )}
            <div className='Example__container__document'>
                <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                    {Array.from(new Array(numPages), (_el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            className='rounded-page'
                        />
                    ))}
                </Document>
            </div>
        </div>
    );
};

export default PdfViewer;
