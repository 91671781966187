import { useState, useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './ImageCropper.css';
import { IImageCropperProps } from './IImageCropperProps';
import { CustomFileInput, Typhography } from 'dfh-ui-library';

export const ImageCropper = (props: IImageCropperProps) => {
    const [image, setImage] = useState(props.defaultImg);
    const cropperRef = useRef<ReactCropperElement>(null);

    const handleFileChange = (file: File | null) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result as string | null;
                if (result) {
                    setImage(result);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    // onCrop updating the image
    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        props.updateImage(cropper?.getCroppedCanvas().toDataURL());
    };

    return (
        <>
            {!image && (
                <Typhography component='div' additionalClasses='grid grid-cols-4'>
                    <Typhography component='div' additionalClasses='col-start-1 col-end-5 cursor-pointer'>
                        <CustomFileInput
                            iconType='BlackUpload'
                            iconAlignment='left'
                            isIconEnabled={true}
                            buttonClasses={`rounded-md border-dashed border-2`}
                            buttonBgClass='bg-[#F1F5F9]'
                            textColor='text-black-540'
                            onFileChange={handleFileChange}
                        />
                    </Typhography>
                </Typhography>
            )}
            {image && (
                <Cropper
                    ref={cropperRef}
                    src={image}
                    className='pt-2'
                    style={{ height: 400, width: '100%' }}
                    aspectRatio={1} // Set aspect ratio to create a square
                    viewMode={2} // Set view mode to 2 (image is contained within the cropper)
                    guides={false} // Hide the guides for a cleaner appearance
                    dragMode='move' // Enable moving the cropper area
                    crop={onCrop} // Attach the cropImage function to a button or event
                />
            )}
        </>
    );
};

export default ImageCropper;
