import { Button, Heading, Icon, ImagePreview, Modal, Typhography } from 'dfh-ui-library';
import moment from 'moment';
import { useState } from 'react';
import { getFristLettersFromString, getRandomBgColor } from '../helpers/userHelpers';
import { IPatient } from 'utils/types/patient.type';

interface DeleteProps {
    rowData: IPatient | undefined;
    isOpenModal: boolean;
    handleCancelButton: () => void;
    handleConfirmButton: () => void;
    avatarUrl?: string;
}
export const DeleteModal = (deleteProps: DeleteProps) => {
    const [isOpen, _setIsOpen] = useState(deleteProps.isOpenModal);

    return (
        <Modal
            isOpen={isOpen}
            onClose={false}
            modalAdditionalClasses={'w-[400px] items-center justify-center flex-col'}
        >
            <Typhography component='div' additionalClasses='flex justify-between pt-4 px-4'>
                <Typhography component='div'>
                    <Heading type='h2' colorType='dark' label={'Delete patient'} additionalClasses='mb-4' />
                </Typhography>
                <Typhography component='div'>
                    <Icon iconType='closeModal' additionalClasses='' onClick={deleteProps.handleCancelButton} />
                </Typhography>
            </Typhography>
            <Typhography component='div' additionalClasses='border border-x-0'>
                <Typhography component='div' additionalClasses='flex justify-between p-6'>
                    <Typhography component='div' additionalClasses='flex items-center'>
                        <ImagePreview
                            text={getFristLettersFromString(
                                `${deleteProps.rowData?.firstName} ${deleteProps.rowData?.lastName}`,
                            )}
                            isSmall={true}
                            imageUrl={deleteProps.avatarUrl}
                            h={'h-16'}
                            w={'w-16'}
                            bgColor={getRandomBgColor()}
                        />
                    </Typhography>
                    <Typhography component='div'>
                        <Typhography component='div' additionalClasses='text-12s text-grey-540'>
                            Name
                        </Typhography>
                        <Typhography component='div' additionalClasses='text-21s text-black-540 pb-4'>
                            {deleteProps.rowData?.firstName + ' ' + deleteProps.rowData?.lastName}
                        </Typhography>
                        <Typhography component='div' additionalClasses='text-12s text-grey-540'>
                            Responsible person(s)
                        </Typhography>
                        <Typhography component='div' additionalClasses='text-21s text-black-540'>
                            {deleteProps.rowData?.responsibleIndividual?.fullName}
                        </Typhography>
                    </Typhography>
                    <Typhography component='div'>
                        <Typhography component='div' additionalClasses='text-12s text-grey-540'>
                            Age
                        </Typhography>
                        <Typhography component='div' additionalClasses='text-21s text-black-540 pb-4'>{`${moment().diff(
                            moment(deleteProps.rowData?.dob),
                            'years',
                        )} years old`}</Typhography>
                        <Typhography component='div' additionalClasses='text-12s text-grey-540'>
                            Medical Provider
                        </Typhography>
                        <Typhography component='div' additionalClasses='text-21s text-black-540'>
                            {deleteProps.rowData?.medicalProvider}
                        </Typhography>
                    </Typhography>
                </Typhography>
                <Typhography>
                    <Typhography component='div' additionalClasses='text-21s font-semibold px-4'>
                        Unresolved issues
                    </Typhography>
                    <Typhography component='div' additionalClasses='px-7'>
                        <ul className='list-disc  p-1 text-21s font-normal'>
                            <li className='mb-3 mt-3 text-[#CFD8DF]'>
                                <Typhography additionalClasses='text-[#21232C]'>Reasonable accommodation</Typhography>
                            </li>
                            <li className='mb-3 text-[#CFD8DF]'>
                                <Typhography additionalClasses='text-[#21232C]'>
                                    {' '}
                                    Homelessness without a case manage
                                </Typhography>
                            </li>
                            <li className='mb-3 text-[#CFD8DF]'>
                                <Typhography additionalClasses='text-[#21232C]'>Poor housing conditions</Typhography>
                            </li>
                            <li className='mb-3 text-[#CFD8DF]'>
                                <Typhography additionalClasses='text-[#21232C]'>
                                    Difficulty affording day-to-day living expenses such as food and rent
                                </Typhography>
                            </li>
                        </ul>
                    </Typhography>
                </Typhography>
            </Typhography>

            <Typhography component='div' additionalClasses='grid grid-cols-4 gap-4 mt-4 pb-4 px-4'>
                <Typhography component='div' additionalClasses='col-start-1 col-span-2'>
                    <Button
                        buttonLabel='Cancel'
                        data-testId='InputField-id2'
                        variants='primaryWhite'
                        onClick={deleteProps.handleCancelButton}
                    />
                </Typhography>
                <Typhography component='div' additionalClasses='col-start-3 col-span-2'>
                    <Button
                        buttonLabel='Delete'
                        data-testId='InputField-id'
                        additionalClasses='bg-[#F23A5C]'
                        variants='primaryRed'
                        onClick={deleteProps.handleConfirmButton}
                    />
                </Typhography>
            </Typhography>
        </Modal>
    );
};
