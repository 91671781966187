import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ILetterSchema } from 'dfh-form-generator';
import { ILetterRequest } from 'utils/types/screener.type';

interface LetterFormState {
    data: { patientId: string; schema: ILetterSchema; letterGenParams: ILetterRequest };
}

const initialState = {
    data: {
        letterGenParams: {
            isSave: false,
            patientId: '',
            solutionId: '',
            patientChallengeId: '',
            configJSON: {},
            varientId: '',
            tempChallageId: '',
        },
    },
} as LetterFormState;

const letterFormSlice = createSlice({
    name: 'letterSchema',
    initialState,
    reducers: {
        setLetterFormSchemaData(state, action) {
            state.data = action.payload;
        },
        setLetterGenParams(state, action) {
            console.log(state);

            state.data = action.payload;
        },
    },
});

export const { setLetterFormSchemaData, setLetterGenParams } = letterFormSlice.actions;
export const getLetterSchemaData = (state: RootState) => state.letterSchema.data.schema;
export const getLetterGenParams = (state: RootState) => state.letterSchema.data.letterGenParams;
export default letterFormSlice.reducer;
