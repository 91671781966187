// ToDoBadge.tsx
import React from 'react';

interface BadgeProps {
    count: number;
    title: string;
    color: string;
}

const BadgeDashboard: React.FC<BadgeProps> = ({ count, title, color }) => {
    return (
        <div className='flex items-center space-x-2 border-b px-2 pb-4 justify-center'>
            <span className='text-12s font-medium'>{title}</span>
            <span
                className={`${color} text-white rounded-full text-12s font-medium flex items-center justify-center gap-2 h-5 w-5`}
            >
                {count}
            </span>
        </div>
    );
};

export default BadgeDashboard;
