import { Button, CheckBoxComponent, Label } from 'dfh-ui-library';
import { Dispatch, SetStateAction, useState } from 'react';
import { DownloadReports } from 'utils/types/reporting.type';

interface DownloadDropdownProps {
    items: DownloadReports[];
    setDownloadReports: Dispatch<SetStateAction<DownloadReports[]>>;
    exportFile: () => void;
}

const DownloadDropdown: React.FC<DownloadDropdownProps> = ({ items, setDownloadReports, exportFile }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const toggleCheckbox = (name: string) => {
        setDownloadReports((items: DownloadReports[]) =>
            items.map((item) => (item.name === name ? { ...item, isChecked: !item.isChecked } : item)),
        );
    };
    return (
        <>
            <div className='inline-block'>
                <Button
                    buttonLabel={'Download CSV'}
                    data-testId='InputField-id'
                    iconAlignment='left'
                    iconType='Download'
                    isIconEnabled
                    variants='primaryWhite'
                    onClick={() => {
                        setIsDropdownOpen((isDropdownOpen: boolean) => !isDropdownOpen);
                    }}
                    iconColor='#0D0D0D'
                    additionalClasses='px-4 !h-10'
                />
                {isDropdownOpen && (
                    <div className='origin-top-right absolute right-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
                        <div className='p-6 '>
                            <div className='grid pb-6 gap-y-3 min-w-[285px]'>
                                {items &&
                                    items.map((item, index) => (
                                        <div key={index} onClick={() => toggleCheckbox(item.name)}>
                                            <CheckBoxComponent onChange={() => {}} checked={item.isChecked} />
                                            <Label
                                                label={item.label}
                                                name={item.name}
                                                additionalClasses='text-sm p-2 cursor-pointer'
                                            />
                                        </div>
                                    ))}
                            </div>
                            <Button
                                buttonLabel={'Download'}
                                additionalClasses='text-sm font-medium p-2.5'
                                variants={'primaryBlue'}
                                isDisabled={items.filter((item) => item.isChecked).length === 0}
                                onClick={() => {
                                    exportFile();
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default DownloadDropdown;
