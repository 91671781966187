import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiResponse, IAuthUserDetailsRequest } from '../utils/types/api.type';
import { baseQueryWithReAuth } from './interceptors';

const baseQuery = baseQueryWithReAuth;

export const userAPI = createApi({
    reducerPath: '/userApi',
    baseQuery,
    endpoints: (builder) => ({
        userDetails: builder.mutation<ApiResponse, IAuthUserDetailsRequest>({
            query: (data) => ({
                url: '/users',
                method: 'PUT',
                body: data,
            }),
        }),
        userPositions: builder.mutation({
            query: (_data) => ({
                url: '/users/positions',
                method: 'GET',
            }),
        }),
        getUsers: builder.query({
            query: (_data) => ({
                url: '/users',
                method: 'GET',
            }),
        }),
    }),
});

export const { useUserDetailsMutation, useUserPositionsMutation, useGetUsersQuery } = userAPI;
