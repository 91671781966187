import { Navigate, RouteObject } from 'react-router-dom';
import RouteRegistry from './routeRegistry';
import AppLayout from 'components/layouts/appLayout';
import HomePageComponent from 'containers/homeHOC/pages/Home';
import PatientListComponent from 'containers/patientHOC/pages/PatientList';
import { HomePropsInit, PatientPropsInit, ReportingPropsInit } from 'utils/init';
import PatientAddComponent from 'containers/patientHOC/pages/PatientAdd';
import PatientViewComponent from 'containers/patientHOC/pages/PatientDetail';
import PatientScreenerComponent from 'containers/patientHOC/pages/PatientScreener';
import PatientDeleteComponent from 'containers/patientHOC/pages/PatientDelete';
import { initPatient } from 'utils/init/patientInit';
import PatientLetterView from 'containers/patientHOC/pages/LetterView';
import ReportingPageComponent from 'containers/reportingHOC/pages/Reporting';

const DashboardRoutes: RouteObject = {
    path: RouteRegistry.landing.path,
    element: <AppLayout />,
    children: [
        {
            path: RouteRegistry.landing.path,
            element: <Navigate to={RouteRegistry.app.paths.root.path} />,
        },
        {
            path: RouteRegistry.app.paths.root.path,
            element: <HomePageComponent {...HomePropsInit} />,
        },
        {
            path: '*',
            element: <Navigate to={RouteRegistry.app.paths.root.path} />,
        },
        {
            path: RouteRegistry.patient.paths.list.path,
            element: <PatientListComponent {...PatientPropsInit} />,
        },
        {
            path: RouteRegistry.patient.paths.add.path,
            element: <PatientAddComponent {...PatientPropsInit} />,
        },
        {
            path: `${RouteRegistry.patient.paths.edit.path}/:patientId`,
            element: <PatientAddComponent {...PatientPropsInit} />,
        },
        {
            path: `${RouteRegistry.patient.paths.view.path}/:patientId`,
            element: <PatientViewComponent {...PatientPropsInit} />,
        },
        {
            path: RouteRegistry.patient.paths.screener.path,
            element: <PatientScreenerComponent {...PatientPropsInit} selectedPatient={initPatient} />,
        },
        {
            path: RouteRegistry.patient.paths.delete.path,
            element: <PatientDeleteComponent {...PatientPropsInit} />,
        },
        {
            path: RouteRegistry.patient.paths.letter.path,
            element: <PatientLetterView {...PatientPropsInit} />,
        },
        {
            path: RouteRegistry.reporting.paths.list.path,
            element: <ReportingPageComponent {...ReportingPropsInit} />,
        },
    ],
};

export default DashboardRoutes;
