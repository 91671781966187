import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { userAPI } from 'api/userAPI';
import User from 'utils/types/user.type';

type InitialState = {
    data: User[];
    isLoading: boolean;
    error: null | string;
};

const userSlice = createSlice({
    name: '/userApi',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
    } as InitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(isAnyOf(userAPI.endpoints.getUsers.matchFulfilled), (state, action) => {
                state.data = action.payload.data;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(userAPI.endpoints.getUsers.matchPending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(userAPI.endpoints.getUsers.matchRejected), (state, action) => {
                if (action.error.message) state.error = action.error.message;
                state.isLoading = false;
            });
    },
});

export default userSlice.reducer;
