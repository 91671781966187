import { isEqual } from 'lodash';

export const base64Image = (image: string): string => {
    // Regular expression for a basic Base64 check
    const base64Regex = /^data:image\/([a-zA-Z]*);base64,([^\s]*)$/;
    if (base64Regex.test(image)) return image;
    return '';
};

export const isValidBase64 = (str: string) => {
    try {
        // Extract the base64 part after the comma
        const base64String = str.split(',')[1];
        // Attempt to decode the string
        const decodedString = atob(base64String);
        // Check if the decoded string can be re-encoded to match the original string
        return isEqual(btoa(decodedString), base64String);
    } catch (error) {
        // If an error occurs during decoding, it's not a valid base64 encoded string
        return false;
    }
};
