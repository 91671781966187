import { useState, useEffect } from 'react';
import LoadingBar from 'react-top-loading-bar';

const LoadingProgress: React.FC<{ isLoading?: boolean }> = ({ isLoading }) => {
    const DEFAULT_LOADING_TIME = 5000;
    const RESET_LOADING_TIME = 0;
    const [progress, setProgress] = useState(DEFAULT_LOADING_TIME);

    useEffect(() => {
        if (!isLoading) {
            // If isLoading is false, set progress to a non-zero value (e.g., 500)
            setProgress(RESET_LOADING_TIME);
        }
        if (isLoading) {
            setProgress(DEFAULT_LOADING_TIME);
        }
    }, [isLoading]);

    return (
        <LoadingBar
            color='#006CD0'
            progress={progress}
            onLoaderFinished={() => setProgress(progress)} // Reset progress only when isLoading is true
            containerClassName='relative'
            height={3}
            shadow={true}
        />
    );
};

export default LoadingProgress;
