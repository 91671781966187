import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Card, LoadingSpinner, NoteProps, Stepper, Typhography } from 'dfh-ui-library';
import React from 'react';
import ScreeningComponent from '../components/ScreeningComponent';
import ScreenerNoteComponent from '../components/ScreenerNoteComponent';
import { PatientPropsInit } from 'utils/init';
import { ISchema, ISchemaStep } from 'dfh-form-generator';
import { RootState } from 'stores/store';
import { useSelector } from 'react-redux';
import ScreenerColorComponent from '../components/ScreenerColorComponent';
import { initPatient } from 'utils/init/patientInit';
import { isEqual } from 'lodash';
import { getProgress } from '../helpers/screenerHelper';
import { IPatient, IPatientScreenerProps, ScreeningComponentMethods } from 'utils/types/patient.type';
import { IScreenerResponse } from 'utils/types/screener.type';

const PatientScreener = (props: IPatientScreenerProps) => {
    const [isCollapsed, _setIsCollapsed] = useState(false);
    const [currentStepSchema, setCurrentStepSchema] = React.useState<ISchemaStep>();
    const [selectedPatient, setSelectedPatient] = React.useState<IPatient>(initPatient);
    const [currentStep, setCurrentStep] = React.useState(0);
    const [viewOnlyMode, _setViewOnlyMode] = React.useState<boolean>(props.viewMode ? props.viewMode : false);
    const screenerRef = useRef<ScreeningComponentMethods>(null);
    const [patientSchema, setPatientSchema] = React.useState<ISchema | undefined>(undefined);
    const [selectedVersion, setSelectedVersion] = React.useState<ISchema | undefined>(props.selectedVersion);
    const [showNote, setShowNote] = React.useState(false);
    const [surveyId, setSurveyId] = React.useState<string | undefined>();
    const screenerData = useSelector((state: RootState) => state.screener.data);
    const screeningComponentRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        handleScreeningComponentScroll();
    }, [currentStep]);

    useMemo(() => {
        if (props.selectedVersion) {
            setPatientSchema(props.selectedVersion);
            setCurrentStep(0);
        } else {
            if (screenerData.filter((data) => isEqual(data.patientId, props.selectedPatient?._id)).length !== 0) {
                setPatientSchema(
                    screenerData.filter((data) => isEqual(data.patientId, props.selectedPatient?._id))[0].schema,
                );
            }
        }
    }, [screenerData, props.selectedPatient, props.selectedVersion]);

    useMemo(() => {
        setSelectedVersion(props.selectedVersion);
    }, [props.selectedVersion]);

    useMemo(() => {
        if (patientSchema && patientSchema?.schemaSteps && patientSchema?.schemaSteps[currentStep]) {
            setCurrentStepSchema(patientSchema?.schemaSteps[currentStep]);
        }
    }, [currentStep, patientSchema]);

    useMemo(() => {
        if (props.selectedPatient) {
            setSelectedPatient(props.selectedPatient);
        }
    }, [props.selectedPatient]);

    useEffect(() => {
        if (!screenerData[0].schema.isLocked && screenerData[0].schema.surveyId) {
            setSurveyId(screenerData[0].schema.surveyId)
        } else {
            setSurveyId(surveyId);
        }
    }, [surveyId]);

    const goToNextStep = () =>
        setCurrentStep((prev) => (prev === patientSchema?.schemaSteps?.length ? prev : prev + 1));

    const goToPreviousStep = () => setCurrentStep((prev) => (prev <= 0 ? prev : prev - 1));

    const goTStep = (index: number) => setCurrentStep(index);

    const submitStep = (viewMode?: boolean) => {
        if (screenerRef.current) {
            screenerRef.current?.handleSubmitClick();
        }
        if (viewMode) {
            setCurrentStep(-1);
            if (props.getChallengeData) props.getChallengeData();
            if (props.saveAndExit) props.saveAndExit();
        }
        props.patientSchemaRefetch && props.patientSchemaRefetch();
    };

    const handleScreeningComponentScroll = () => {
        screeningComponentRef && screeningComponentRef.current?.scrollIntoView(true);
    };

    const getSurvey = (survey: IScreenerResponse) => {
        const surveyId: string = survey._id;
        setSurveyId(surveyId);
    }

    const handleAddItem = async (_items: NoteProps) => {
        const response = _items.note && (props.saveScreenerNotes && await props.saveScreenerNotes({ note: _items, survey: surveyId, noteType: 'Survey' }));
        if (response) {
            setShowNote(false);
        }
    }

    if (patientSchema?.schemaSteps == null) {
        return <LoadingSpinner />;
    } else
        return (
            <>
                {patientSchema?.schemaSteps == null && <LoadingSpinner />}
                <Typhography component='div' additionalClasses='flex flex-col'>
                    <Typhography additionalClasses={!props.viewMode ? 'mt-8' : ''}>
                        <Card additionalClasses={props.viewMode ? 'w-full h-[calc(100vh-402px)]' : 'w-full h-[calc(100vh-316px)]'} cardType={!props.viewMode ? 'whiteBorder' : 'white'}>
                            <Typhography component='div' additionalClasses={props.viewMode ? 'flex flex-row h-[calc(100vh-373px)]' : 'flex flex-row h-[calc(100vh-402px)]'}>
                                <Typhography
                                    component='div'
                                    additionalClasses={
                                        isCollapsed
                                            ? `basis-0/12 relative `
                                            : `block basis-3/12 relative border-r border-gray-300 overflow-y-auto`
                                    }
                                >
                                    <Typhography
                                        component='div'
                                        additionalClasses={isCollapsed ? `hidden` : `block py-3`}
                                    >
                                        {((screenerData[0].schema.isLocked !== undefined && !screenerData[0].schema.isLocked) || (surveyId !== undefined && surveyId)) && (
                                            <>
                                                <Typhography 
                                                    component='div' 
                                                    additionalClasses={`cursor-pointer text-21s ml-2 mr-2 px-2 py-2 mb-1 ${showNote === true ? `rounded-lg bg-[#F1F5F9] font-medium` : `font-normal`}`}
                                                    onClick={() => {
                                                        setShowNote(!showNote);
                                                        setCurrentStep(currentStep);
                                                        props.patientSchemaRefetch && props.patientSchemaRefetch();
                                                    }}
                                                >
                                                    Updates and Next Steps
                                                </Typhography>
                                                <hr />
                                            </>
                                        )}
                                        <Typhography
                                            component='div'
                                            additionalClasses='text-18s font-semibold px-4 pb-4'
                                        >
                                            <Typhography component='div' additionalClasses='flex justify-between'>
                                                <Typhography component='span' additionalClasses='mt-3'>
                                                    Steps
                                                </Typhography>
                                                {!props.viewMode && (
                                                    <Typhography additionalClasses='flex-none text-12s font-normal text-btn-black my-2 pt-2 px-1 py-1 rounded-full bg-[#F1F5F9] text-blue w-8 h-8 flex justify-center'>
                                                        {`${getProgress(patientSchema)}%`}
                                                    </Typhography>
                                                )}
                                            </Typhography>
                                            {!props.viewMode && (
                                                <Typhography component='div' additionalClasses=''>
                                                    <Stepper percentage={getProgress(patientSchema)} />
                                                </Typhography>
                                            )}
                                        </Typhography>
                                        <Typhography component='div' additionalClasses=''>
                                            {patientSchema?.schemaSteps?.map((items: any, index: number) => (
                                                <Typhography
                                                    component='div'
                                                    additionalClasses={`pb-2 cursor-pointer`}
                                                    key={index}
                                                >
                                                    <Typhography
                                                        component='div'
                                                        additionalClasses={`${index === currentStep && ``}  `}
                                                        onClick={() => goTStep(index)}
                                                    >
                                                        <Typhography
                                                            component='div'
                                                            additionalClasses={`flex justify-between py-2  text-21s  ml-2 mr-2  px-2 ${!showNote && index === currentStep ? `rounded-lg bg-[#F1F5F9] font-medium` : `font-normal`}`}
                                                        >
                                                            <Typhography
                                                                component='div'
                                                                additionalClasses=''
                                                                key={index}
                                                            >
                                                                {items?.mainTitle}
                                                            </Typhography>
                                                            <Typhography>
                                                                <ScreenerColorComponent
                                                                    itemsData={items}
                                                                    schema={patientSchema}
                                                                />
                                                            </Typhography>
                                                        </Typhography>
                                                    </Typhography>
                                                </Typhography>
                                            ))}
                                            {!props.viewMode && (
                                                <Typhography component='div' additionalClasses='px-2 pt-2'>
                                                    <Button
                                                        buttonLabel='Save and exit'
                                                        variants='primaryWhite'
                                                        onClick={() => submitStep(true)}
                                                    />
                                                </Typhography>
                                            )}
                                        </Typhography>
                                    </Typhography>
                                </Typhography>
                                <Typhography component='div' additionalClasses={isCollapsed ? `w-full` : `basis-9/12 overflow-y-auto`}>
                                    {showNote ? (
                                        <ScreenerNoteComponent 
                                            showNote={showNote} 
                                            patientSchema={props.patientSchema} 
                                            setShowNote={setShowNote} 
                                            handleAddItem={handleAddItem} 
                                            patientSchemaRefetch={props.patientSchemaRefetch}
                                        />
                                    ) : (
                                        <div ref={screeningComponentRef} className='px-4 p-4 gap-2 box-content'>
                                            {currentStepSchema && (
                                                <ScreeningComponent
                                                    {...PatientPropsInit}
                                                    schemaStep={currentStepSchema}
                                                    ref={screenerRef}
                                                    goToNextStep={goToNextStep}
                                                    updateScreenerSteps={props.updateScreenerSteps}
                                                    selectedPatient={selectedPatient}
                                                    goToScreenerEdit={props.goToScreenerEdit}
                                                    viewMode={viewOnlyMode}
                                                    isEditable={props.isEditable}
                                                    selectedVersion={selectedVersion}
                                                    getSurvey={getSurvey}
                                                ></ScreeningComponent>
                                            )}
                                        </div>
                                    )}
                                </Typhography>
                            </Typhography>

                            {!props.viewMode && (
                                <Typhography
                                    component='div'
                                    additionalClasses='flex flex-row w-full border-t justify-between border-gray-300 place-items-center py-4 px-6 bg-white fixed bottom-0 sticky'
                                >
                                    <Typhography component='div' additionalClasses='flex justify-self-start'>
                                        <Button
                                            buttonLabel='Back'
                                            variants='primaryWhite'
                                            onClick={goToPreviousStep}
                                            isIconEnabled={true}
                                            iconAlignment={'left'}
                                            iconType='BlackPrevIcon'
                                        />
                                    </Typhography>

                                    <Typhography
                                        component='div'
                                        additionalClasses='flex items-center gap-2 justify-self-end'
                                    >
                                        <Button
                                            buttonLabel={
                                                patientSchema?.schemaSteps?.length == currentStep + 1
                                                    ? 'Finish'
                                                    : 'Next step'
                                            }
                                            additionalClasses={`text-nowrap ${patientSchema?.schemaSteps?.length == currentStep && 'px-6'}`}
                                            variants='primaryBlue'
                                            onClick={() =>
                                                submitStep(patientSchema?.schemaSteps?.length == currentStep + 1)
                                            }
                                            isIconEnabled={patientSchema?.schemaSteps?.length !== currentStep}
                                            iconAlignment={'right'}
                                            iconType='WhiteNextIcon'
                                            iconClass='mt-1 ml-2'
                                        />
                                    </Typhography>
                                </Typhography>
                            )}
                        </Card>
                    </Typhography>
                </Typhography>
            </>
        );
};
export default PatientScreener;
