import RouteRegistry from './routeRegistry';
import AuthLayout from '../components/layouts/authLayout';
import Login from '../containers/loginHOC/pages/Login';
import Signup from '../containers/signupHoc/pages/Signup';
import Verification from '../containers/signupHoc/pages/Verification';
import UserDetails from '../containers/signupHoc/pages/UserDetails';
import ResetPassword from '../containers/loginHOC/pages/ResetPassword';
import ResetEmail from '../containers/loginHOC/pages/ResetEmail';
import NewPassword from '../containers/loginHOC/pages/NewPassword';
import { loginPropsInit, signUpPropsInit } from 'utils/init';

const AuthRoutes = {
    path: '/auth',
    element: <AuthLayout />,
    children: [
        {
            path: RouteRegistry.auth.paths.signIn.path,
            element: <Login {...loginPropsInit} />,
        },
        {
            path: RouteRegistry.auth.paths.signUp.path,
            element: <Signup {...signUpPropsInit} />,
        },
        {
            path: RouteRegistry.auth.paths.verification.path,
            element: <Verification {...signUpPropsInit} />,
        },
        {
            path: RouteRegistry.auth.paths.userDetails.path,
            element: <UserDetails {...signUpPropsInit} />,
        },
        {
            path: RouteRegistry.auth.paths.reset.path,
            element: <ResetPassword {...loginPropsInit} />,
        },
        {
            path: RouteRegistry.auth.paths.resetEmail.path,
            element: <ResetEmail {...loginPropsInit} />,
        },
        {
            path: RouteRegistry.auth.paths.newPassword.path,
            element: <NewPassword {...loginPropsInit} />,
        },
    ],
};

export default AuthRoutes;
