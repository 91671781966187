import { Button, Heading, Icon, Modal, Typhography } from 'dfh-ui-library';
import { useState } from 'react';

interface WarningProps {
    isOpenModal: boolean;
    handleCancel: () => void;
    handleContinue: () => void;
}
export const WarningModal = (props: WarningProps) => {
    const [isOpen, _setIsOpen] = useState(props.isOpenModal);

    return (
        <Modal
            isOpen={isOpen}
            onClose={false}
            modalAdditionalClasses={'w-[400px] items-center justify-center flex-col'}
        >
            <Typhography component='div' additionalClasses='flex justify-between pt-4 px-4'>
                <Typhography component='div'>
                    <Heading type='h2' colorType='dark' label={'Warning'} additionalClasses='mb-4' />
                </Typhography>
                <Typhography component='div'>
                    <Icon iconType='closeModal' additionalClasses='' onClick={props.handleCancel} />
                </Typhography>
            </Typhography>
            <Typhography component='div' additionalClasses='border border-x-0'>
                <Typhography component='div' additionalClasses='flex justify-between p-6'>
                    <Typhography component='div' additionalClasses='flex items-center'>
                        <Typhography component='div' additionalClasses='text-sm text-black-540 text-center'>
                            You have changed the patient's information.<br></br>Are you sure you don't want to save the
                            changes?
                        </Typhography>
                    </Typhography>
                </Typhography>
            </Typhography>

            <Typhography component='div' additionalClasses='grid grid-cols-4 gap-4 mt-4 pb-4 px-4'>
                <Typhography component='div' additionalClasses='col-start-1 col-span-2'>
                    <Button
                        buttonLabel='Cancel'
                        data-testId='InputField-id2'
                        variants='primaryWhite'
                        onClick={props.handleCancel}
                    />
                </Typhography>
                <Typhography component='div' additionalClasses='col-start-3 col-span-2'>
                    <Button
                        buttonLabel='Continue'
                        data-testId='InputField-id'
                        variants='primaryBlue'
                        onClick={props.handleContinue}
                    />
                </Typhography>
            </Typhography>
        </Modal>
    );
};
