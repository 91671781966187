import { Button, IconInput, LoadingSpinner, Message, Typhography } from 'dfh-ui-library';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import loginHOC from '../index';
import { ILoginProps } from 'utils/types/auth.type';

const ResetPassword = (props: ILoginProps) => {
    // Reset password form config
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Email is required'),
        }),
        onSubmit: async (values) => {
            const newPassResult = await props.apiMethods.resetPassword({
                email: values.email,
            });
            if (newPassResult?.data) {
                formik.resetForm();
                props.navigateMethods.navigateToRestEmail();
            }
        },
    });

    return (
        <Typhography component='div' additionalClasses='relative top-24'>
            {props.isLoading && <LoadingSpinner />}
            {props.errorMsg.isVisible && <Message type={props.errorMsg.type} text={props.errorMsg.msg} />}
            <Typhography type='authHeader'>Reset your password</Typhography>
            <form onSubmit={formik.handleSubmit}>
                <Typhography component='div' additionalClasses='grid grid-cols-1 mt-4'>
                    <Typhography component='div' additionalClasses='py-2'>
                        <Typhography component='div'>
                            <IconInput
                                iconClassLeft='top-5 left-4'
                                isIconLeft
                                leftIcon='Email'
                                label='Email'
                                placeholder='example@mail.com'
                                name='email'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                                isAdditionalErrorInput={true}
                                additionalErrorClasses='text-red-500 text-xs pt-1'
                                additionalClasses={formik.errors.email ? 'border-red-500' : ''}
                                isBorderedError={formik.errors.email ? true : false}
                            />
                        </Typhography>
                    </Typhography>

                    <Typhography additionalClasses='my-8'>
                        <Button
                            type='submit'
                            buttonLabel='Reset Password'
                            variants='large'
                            additionalClasses='bg-[#006CD0] hover:bg-[#006CD0]'
                            isDisabled={!(formik.isValid && formik.dirty)}
                        ></Button>
                    </Typhography>
                    <Typhography additionalClasses='py-2 text-center'>
                        <Typhography component='p'>
                            <Typhography type='textMediumGray'>Back to</Typhography>
                            <Typhography
                                type='linkTextMedium'
                                additionalClasses='cursor-pointer ml-1'
                                onClick={props.navigateMethods.navigateToSignIn}
                            >
                                Login
                            </Typhography>
                            <Typhography type='textMediumGray' additionalClasses='ml-1'>
                                or
                            </Typhography>

                            <Typhography
                                type='linkTextMedium'
                                additionalClasses='cursor-pointer ml-1'
                                onClick={props.navigateMethods.navigateToSignUp}
                            >
                                Sign Up
                            </Typhography>
                        </Typhography>
                    </Typhography>
                </Typhography>
            </form>
        </Typhography>
    );
};
const ResetPasswordComponent = loginHOC(ResetPassword);
export default ResetPasswordComponent;
