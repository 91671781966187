import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ApiResponse } from './api.type';
import { SerializedError } from '@reduxjs/toolkit';

export interface IReportingRequestParams {
    fromDate: string;
    toDate: string;
    type: ReportingType;
}

export enum ReportingType {
    PATIENTS_ENROLLED = 'patients-enrolled',
    SCREENER_OUTCOMES = 'screener-outcomes',
    PATIENT_AT_LEAST_ONE_SCREENER = 'patient-at-least-one-screener',
    CHALLENGES_PER_PATIENTS = 'challenges-per-patients',
    CHALLENGES_RESOLVED = 'challenges-resolved',
    FOOD_INSECURITY = 'food-insecurity',
    TRANSPORTATION_INSECURITY = 'transportation-insecurity',
    HOUSING_INSECURITY = 'housing-insecurity',
}

export interface IReportingHOCProps {
    isFetching: boolean;
    getReporting: (req: IReportingRequestParams) => {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    };
}

export enum ScreenerOutcomesTypes {
    withChallenges = 'Screeners with challenges',
    withoutChallenges = 'Screeners without challenges',
}

export enum AssignedStatus {
    assigned = 'Assigned',
    unassigned = 'unassigned',
}

export type ChartDatasetType = {
    label: string;
    data: number[];
    backgroundColor: string;
};

export type ChartDataType = {
    labels: string[];
    datasets: ChartDatasetType[];
};

export type LabelData = {
    year: number;
    month: number;
    monthLabel: string;
};

interface IMergedData {
    monthLabel: string;
}

export type PatientsEnrolled = {
    year: number;
    month: number;
    assigned: number;
    unassigned: number;
};

export type PatientsEnrolledMergedData = PatientsEnrolled & IMergedData;

export type PatientScreenedAtLeatOnce = {
    year: number;
    month: number;
    patientsWithScreener: number;
};

export type PatientScreenedAtLeatOnceMergedData = PatientScreenedAtLeatOnce & IMergedData;

export type ScreenerOutcomes = {
    year: number;
    month: number;
    screenersWithChallenges: number;
    screenersWithoutChallenges: number;
};

export type ScreenerOutcomesMergedData = ScreenerOutcomes & IMergedData;

export type ChallengesPerPatients = {
    year: number;
    month: number;
    averageChallengesPerPatient: number;
};

export type ChallengesPerPatientsMergedData = ChallengesPerPatients & IMergedData;

export type TotalChallengesResolved = {
    year: number;
    month: number;
    resolvedChallengesCount: number;
};

export type TotalChallengesResolvedMergedData = TotalChallengesResolved & IMergedData;

export type CSVDataHeaders = {
    label: string;
    key: string;
};

export type ExcelData = {
    year: number;
    month: number;
    monthLabel: string;
    assigned?: number;
    unassigned?: number;
    patientsWithScreener?: number;
    screenersWithChallenges?: number;
    screenersWithoutChallenges?: number;
    averageChallengesPerPatient?: number;
    resolvedChallengesCount?: number;
};

export type Insecurity = {
    year: number;
    month: number;
    matchedPatients: number;
    totalPatients: number;
};

export type InsecurityMergedData = {
    year: number;
    month: number;
    monthLabel: string;
    matchedPatients: number;
    totalPatients: number;
    percentage: number;
};

export interface DownloadReports {
    isChecked: boolean;
    label: string;
    name: string;
}
