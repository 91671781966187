export async function urlToFile(url: string, filename: string, mimeType: string) {
    try {
        // Fetch the image from the URL
        const response = await fetch(url);
        // Check if the response was successful
        if (!response.ok) throw new Error('Network response was not ok.');
        // Create a blob from the response data
        const blob = await response.blob();
        // Create and return the file object
        return new File([blob], filename, { type: mimeType });
    } catch (error) {
        console.error('Error fetching and converting the image:', error);
        return null;
    }
}

export function printDocument(url: string) {
    // Create an iframe element
    const iframe = document.createElement('iframe');

    // Set the iframe's style to be discreet on the page
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';

    // Append the iframe to the document body
    document.body.appendChild(iframe);

    // When the iframe is loaded, print its content
    iframe.onload = function () {
        try {
            // This will trigger the print dialog in most browsers
            iframe?.contentWindow?.print();
        } catch (e) {
            console.error('Print failed:', e);
        }
    };

    // Set the src to the document you want to print
    iframe.src = url;
}

export function downloadDocument(url: string) {
    // Open the document in a new window or tab
    const printWindow = window.open(url, '_blank');

    if (printWindow) {
        // Try to print the document - this might not work due to cross-origin restrictions
        printWindow.onload = function () {
            try {
                // Attempt to call print (may not work due to cross-origin restrictions)
                printWindow.print();
            } catch (e) {
                console.error('Print failed:', e);
                // Inform the user they'll need to manually print the document
                alert('Please press CTRL+P to print the document');
            }
        };
    }
}
