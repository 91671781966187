import { ITodoToggleRequestParams } from './../utils/types/patient.type';
import { IReactionRequest } from 'utils/types/api.type';
import { IHomeRepository } from './IHomeRepository';
import {
    useLazyGetDashboardMetrixQuery,
    useLazyGetDashboardTodosChallengesQuery,
    useLazyGetDashboardUpdatesQuery,
    useLazyGetDashboardUrgentChallengesQuery,
    useUpdateReactionMutation,
    useToggleDashboardTodoCheckboxMutation,
} from 'api/homeAPI';

export const useHomeRepository = (): IHomeRepository => {
    const [getDashboardUpdatesFunction] = useLazyGetDashboardUpdatesQuery();
    const [getDashboardMetrixFunction] = useLazyGetDashboardMetrixQuery();
    const [updateReactionFunction] = useUpdateReactionMutation();
    const [getDashboardUrChallengesFunction] = useLazyGetDashboardUrgentChallengesQuery();
    const [getDashboardTodosFunction] = useLazyGetDashboardTodosChallengesQuery();
    const [useToggleDashboardTodoCheckboxFunction] = useToggleDashboardTodoCheckboxMutation();

    const getDashboardUpdates = async (userId?: string): Promise<any> => {
        const result = getDashboardUpdatesFunction(userId ?? undefined);
        return result;
    };

    const getDashboardMetrix = async (userId?: string): Promise<any> => {
        const result = getDashboardMetrixFunction(userId ?? undefined);
        return result;
    };
    const getDashboardUrgentChallenges = async (userId: string): Promise<any> => {
        const result = getDashboardUrChallengesFunction(userId);
        return result;
    };

    const getDashboardTodosChallenges = async (userId: string): Promise<any> => {
        const result = getDashboardTodosFunction(userId);
        return result;
    };
    const updateReactions = async (challengeReq: IReactionRequest): Promise<any> => {
        // console.log(challengeReq);

        try {
            const result = updateReactionFunction(challengeReq);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during edit patient:', error);
            throw new Error('An error occurred during edit patient');
        }
    };

    const toggleDashboardTodoCheckbox = async (req: ITodoToggleRequestParams): Promise<any> => {
        try {
            const result = useToggleDashboardTodoCheckboxFunction(req);
            
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occured during toggling the checkbox', error);
            throw new Error('An error occured during toggling the checkbox');
        }
    };
    
    return {
        getDashboardUpdates,
        updateReactions,
        getDashboardMetrix,
        getDashboardUrgentChallenges,
        getDashboardTodosChallenges,
        toggleDashboardTodoCheckbox
    };
};
