import { IPatientProps } from 'utils/types/patient.type';
import patientHOC from '..';
import LetterComponent from '../components/LetterComponent';
import { useLocation } from 'react-router-dom';
import { RootState } from 'stores/store';
import { useDispatch, useSelector } from 'react-redux';
import { getLetterSchemaObject } from '../helpers/screenerHelper';
import { useEffect } from 'react';
import LoadingProgress from 'components/Loading/LoadingProgress';
import { ILetterRequest } from 'utils/types/screener.type';
import { setLetterGenParams } from 'stores/letterFormSlice';

const PatientLetterView = (props: IPatientProps) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const receivedData = location.state;
    const letterDSchemaData = useSelector((state: RootState) => state.letterSchema.data);
    const modifiedData = getLetterSchemaObject(letterDSchemaData?.schema, receivedData.id);

    const backToView = (req?: ILetterRequest) => {
        if (receivedData?.releaseInfo?.index > -1) {
            props.navigateToAdd(receivedData.id, undefined, receivedData.releaseInfo.index, req?.fileId);
        } else {
            dispatch(setLetterGenParams({ letterGenParams: req }));
            props.navigateToView && props.navigateToView(receivedData.id, undefined, true, req);
        }
    };

    useEffect(() => {
        if (letterDSchemaData.letterGenParams?.patientId == '') {
            backToView();
        }
    }, [letterDSchemaData]);
    return (
        <>
            <div className='relative'>{props.isLoading && <LoadingProgress isLoading={props.isLoading} />}</div>

            {modifiedData && (
                <LetterComponent
                    navigateToView={props.navigateToView}
                    schema={modifiedData}
                    backToView={backToView}
                    receivedData={receivedData}
                    updateLetterSchemaValues={props.updateLetterSchemaValues}
                    varientId={receivedData.varientId}
                    varient={receivedData.varient}
                    updateLetterSchemaValuesForRInfo={props.updateLetterSchemaValuesForRInfo}
                    isReleaseInfo={receivedData.releaseInfo && receivedData.releaseInfo.index > -1}
                />
            )}
        </>
    );
};
export default patientHOC(PatientLetterView);
