import { IAuthRepository } from './IAuthRepository';
import {
    useSignUpMutation,
    useSignInMutation,
    useVerifyMutation,
    useResendCodeMutation,
    useResetPasswordMutation,
    useNewPasswordMutation,
    useSignOutMutation,
    useRefreshMutation,
} from '../api/authAPI';
import { AuthApiRequest, IAuthNewPasswordRequest, IAuthRequest, IAuthVerifyRequest } from '../utils/types/api.type';

export const useAuthRepository = (): IAuthRepository => {
    const [mutateFunction] = useSignUpMutation();
    const [mutateVerifyFunction] = useVerifyMutation();
    const [mutateResendFunction] = useResendCodeMutation();
    const [mutateLoginFunction] = useSignInMutation();
    const [mutateResetFunction] = useResetPasswordMutation();
    const [mutateNewPassFunction] = useNewPasswordMutation();
    const [mutateSignOutFunction] = useSignOutMutation();
    const [mutateRefreshFunction] = useRefreshMutation();

    const signUp = async (userData: AuthApiRequest): Promise<any> => {
        try {
            const result = mutateFunction(userData); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during sign-up:', error);
            throw new Error('An error occurred during sign-up');
        }
    };

    const verify = async (verifyData: IAuthVerifyRequest): Promise<any> => {
        try {
            const result = mutateVerifyFunction(verifyData);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during verify:', error);
            throw new Error('An error occurred during verify');
        }
    };

    const resendCode = async (resendData: IAuthRequest): Promise<any> => {
        try {
            const result = mutateResendFunction(resendData);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during resend:', error);
            throw new Error('An error occurred during resend');
        }
    };

    const login = async (userData: AuthApiRequest): Promise<any> => {
        try {
            const result = mutateLoginFunction(userData);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during sign-in:', error);
            throw new Error('An error occurred during sign-in');
        }
    };

    const resetPassword = async (resentData: IAuthRequest): Promise<any> => {
        try {
            const result = mutateResetFunction(resentData);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during reset:', error);
            throw new Error('An error occurred during reset');
        }
    };

    const newPassword = async (userData: IAuthNewPasswordRequest): Promise<any> => {
        try {
            const result = mutateNewPassFunction(userData);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during new password:', error);
            throw new Error('An error occurred during password');
        }
    };

    const signOut = async (userData: IAuthRequest): Promise<any> => {
        try {
            const result = mutateSignOutFunction(userData);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during signOut:', error);
            throw new Error('An error occurred during signOut');
        }
    };

    const refresh = async (userData: IAuthRequest): Promise<any> => {
        try {
            const result = mutateRefreshFunction(userData);
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during refresh:', error);
            throw new Error('An error occurred during refresh');
        }
    };

    return {
        signUp,
        verify,
        resendCode,
        login,
        resetPassword,
        newPassword,
        signOut,
        refresh,
    };
};
