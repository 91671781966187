import { Icon } from 'dfh-ui-library';
import { useEffect, useState } from 'react';
import StepStatusColor from 'utils/enum';
import { CheckWhite } from '../helpers/iconHelper';
import _, { isEqual } from 'lodash';
import { IQuestionSchema, ISchemaStep, isComponentVisible } from 'dfh-form-generator';
const ScreenerColorComponent: React.FC<{ schema: any; itemsData: any }> = ({ schema, itemsData }) => {
    const [valueCount, setValueCount] = useState<number>(0);
    const [stepArray, setStepArray] = useState<any>([]);

    useEffect(() => {
        if (schema?.schemaSteps) {
            const schemaSteps = schema.schemaSteps.filter((value: any) => itemsData._id === value._id);
            const updatedStepArray = schemaSteps[0]?.formRows.flatMap((values: any) =>
                values.questionList.map((items: any) => items),
            );

            const values = getValues(schema.schemaSteps);
            setStepArray(
                updatedStepArray.filter((vals: IQuestionSchema) => {
                    const isvisible = !vals.visibleIf || isComponentVisible(vals.visibleIf, values);
                    return isvisible;
                }),
            );
        }
    }, [schema, itemsData]);

    useEffect(() => {
        let valueCnt = 0;
        if (schema?.schemaSteps) {
            const values = getValues(schema.schemaSteps);
            stepArray
                .filter((vals: any) => !vals.visibleIf || isComponentVisible(vals.visibleIf, values))
                .forEach((item: any) => {
                    if (
                        item.question.selected && // Check if item.field.selected exists
                        !isEqual(item.question.selected, {}) &&
                        item.question.selected.value !== '' && // Check if item.field.selected.value is not empty
                        !isEqual(item.question.selected.value, '')
                    ) {
                        valueCnt = valueCnt + 1;
                    }
                });
        }
        setValueCount(valueCnt);
    }, [stepArray]);

    const getValues = (schemas: ISchemaStep[]): {} => {
        let values = {};
        schemas.map((schema) => {
            schema.formRows.map((row) => {
                const fieldValue = row.questionList.reduce((obj, item) => {
                    return {
                        ...obj,
                        [item.question._id]: item.question.selected
                            ? { ...item.question.selected, answerPrevData: item.question?.answerPrevData }
                            : { value: '', answerPrevData: item.question?.answerPrevData },
                    };
                }, {});
                values = { ...values, ...fieldValue };
            });
        });
        return values;
    };

    return (
        <>
            {valueCount > 0 ? (
                stepArray?.length === valueCount ? (
                    <Icon iconType='Check' color={StepStatusColor.Completed} />
                ) : (
                    <Icon iconType='Check' color={StepStatusColor.InProgress} />
                )
            ) : (
                <CheckWhite />
            )}
        </>
    );
};

export default ScreenerColorComponent;
