import { useLocation } from 'react-router-dom';
import { Button, IconInput, LoadingSpinner, Message, Select, Typhography } from 'dfh-ui-library';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import signUpHOC from '../index';
import { ISignUpProps } from 'utils/types/auth.type';
import { IUserPosition } from 'utils/types/user.type';

const UserDetails = (props: ISignUpProps) => {
    const { state } = useLocation();
    const [positions, setPositions] = useState<{ value: string; displayName: string }[]>([]);

    useEffect(() => {
        if (!state && !state?.email) props.navigateMethods.navigateToSignIn();
        getPositions(); // Fetch user positions
    }, []);

    const getPositions = async () => {
        const positions = await props.apiMethods.userPositions();
        if (positions?.data) {
            setPositions(
                positions.data.data.reduce((acc: { value: any; displayName: any }[], position: IUserPosition) => {
                    acc.push({
                        value: position._id,
                        displayName: position.name,
                    });
                    return acc;
                }, []),
            );
        }
    };

    // User details form config
    const formik = useFormik({
        initialValues: {
            name: '',
            position: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            position: Yup.string().required('Position is required'),
        }),
        onSubmit: async (values) => {
            const userResult = await props.apiMethods.userDetails({
                email: state?.email,
                fullName: values.name,
                position: values.position,
            });
            if (userResult?.data) {
                props.navigateMethods.navigateToHome();
            }
        },
    });

    return (
        <>
            {props.isLoading && <LoadingSpinner />}
            {props.errorMsg.isVisible && <Message type={props.errorMsg.type} text={props.errorMsg.msg} />}
            <Typhography type='authHeader' additionalClasses='max-w-md'>
                Hi! Let's get to know each other better
            </Typhography>
            <form onSubmit={formik.handleSubmit}>
                <Typhography component='div' additionalClasses='grid grid-cols-1 mt-4'>
                    <Typhography component='div' additionalClasses='py-2'>
                        <Typhography component='div'>
                            <IconInput
                                iconClassLeft='top-4 left-4'
                                label='Name'
                                type='text'
                                placeholder='Enter your name'
                                isIconLeft
                                leftIcon='User'
                                name='name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
                                isAdditionalErrorInput={true}
                                additionalErrorClasses='text-red-500 text-xs pt-1'
                                additionalClasses={formik.errors.name ? 'border-red-500' : ''}
                                isBorderedError={formik.errors.name ? true : false}
                            />
                        </Typhography>
                    </Typhography>
                    <Typhography component='div' additionalClasses='py-2'>
                        <Typhography component='div'>
                            <Select
                                defaultText='Choose your position'
                                options={positions}
                                label='Position'
                                id='position'
                                name='position'
                                value={formik.values.position}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.position && formik.errors.position ? formik.errors.position : ''}
                                additionalClasses={formik.errors.position ? 'border-red-500' : ''}
                                iconClasses='.right-0 right-4 top-9'
                            />
                        </Typhography>
                    </Typhography>
                    <Typhography additionalClasses='py-2 my-8'>
                        <Button
                            type='submit'
                            buttonLabel='Lets Start'
                            variants='large'
                            additionalClasses='bg-[#006CD0] cursor-pointer hover:bg-[#006CD0]'
                            isDisabled={!(formik.isValid && formik.dirty)}
                        ></Button>
                    </Typhography>
                </Typhography>
            </form>
        </>
    );
};

const UserDetailsComponent = signUpHOC(UserDetails);
export default UserDetailsComponent;
