import { Button, Heading, Modal, Typhography } from 'dfh-ui-library';
import ImageCropper from './ImageCropper';
import { IImageCropperProps } from './IImageCropperProps';
import { useState } from 'react';

const ImageCropperModal = (props: IImageCropperProps) => {
    const [image, setImage] = useState(props.defaultImg);

    const updateImg = (img: string | undefined) => {
        if (img) {
            setImage(img);
            props.updateImage(img);
        }
    };
    return (
        <>
            <Modal
                isOpen={true}
                onClose={props.closeModal}
                modalAdditionalClasses={'w-[400px] items-center justify-center px-4 py-8 flex-col pt-2 pb-4'}
            >
                <Heading
                    type='h2'
                    colorType='dark'
                    label={props.header ? props.header : 'Upload Patient Image'}
                    additionalClasses='mb-4'
                />
                <Typhography component='div' additionalClasses='border border-x-0 py-4'>
                    <ImageCropper defaultImg={props.defaultImg} updateImage={updateImg} closeModal={props.closeModal} />
                </Typhography>
                <Typhography component='div' additionalClasses='grid grid-cols-4 gap-4 mt-4'>
                    <Typhography component='div' additionalClasses='col-start-1 col-span-2'>
                        <Button
                            buttonLabel='Cancel'
                            data-testId='InputField-id2'
                            variants='primaryWhite'
                            onClick={() => props.closeModal(false)}
                        />
                    </Typhography>
                    <Typhography component='div' additionalClasses='col-start-3 col-span-2'>
                        <Button
                            buttonLabel='Crop and save'
                            data-testId='InputField-id'
                            variants='primaryBlue'
                            onClick={() => props.closeModal(true)}
                            isDisabled={!image}
                        />
                    </Typhography>
                </Typhography>
            </Modal>
        </>
    );
};
export default ImageCropperModal;
