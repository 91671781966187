import Loading from 'components/Loading/Loding';
import { useAppSelector } from 'hooks/hooks';
import useAuthRedirect from 'hooks/useAuthRedirect';
import { Fragment, Suspense, useEffect, useState } from 'react';

import AppRoutes from 'routes';
import { selectUser } from 'stores/appSlice';

function App() {
    const currUser = useAppSelector(selectUser);
    const [loaded, setLoaded] = useState<boolean>(false);
    useAuthRedirect();
    useEffect(() => {
        //currUser need to check
        setLoaded(true);
    }, [currUser]);

    return (
        <Fragment>
            <Suspense fallback={<Loading loading={true} />}>{loaded && <AppRoutes />}</Suspense>
        </Fragment>
    );
}

export default App;
