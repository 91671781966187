import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Button, NoteProps, Timeline, Typhography } from 'dfh-ui-library';
import { useEffect, useState } from 'react';
import { InfoIcon40 } from '../helpers/iconHelper';
import { ApiResponse } from 'utils/types/api.type';
import { IPatientChallengeDto } from 'utils/types/patient.type';

interface ChallengeTimelineProps {
    patientId: string;
    challengeId: string;
    setShowChallengeTimeline: (status: boolean) => void;
    getPatientChallengeTimeLine: (patientId: string, challengeId: string) => {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    };
    handleCopyCurrentNote?: (note: NoteProps) => void;
    rowDetails?: IPatientChallengeDto;
}

const ChallengeTimeline: React.FC<ChallengeTimelineProps> = ({
    patientId,
    challengeId,
    getPatientChallengeTimeLine,
    setShowChallengeTimeline,
    handleCopyCurrentNote,
    rowDetails
}) => {
    const [challengeTimelineData, setChallengeTimelineData] = useState<any>([]);

    const { data: responseData, refetch: refetchChallengeTimeline } = getPatientChallengeTimeLine(patientId, challengeId);

    useEffect(() => {
        refetchChallengeTimeline();
    }, [challengeId]);

    useEffect(() => {
        const challengeTimelineData = responseData?.data.filter(
            (timelineData: any) => timelineData.type === 'Challenge',
        );
        setChallengeTimelineData(challengeTimelineData);
    }, [responseData]);

    return (
        <section
            className=' w-1/4 xl:w-1/4 min-w-80 mt-3 lg:mt-0 bg-white rounded-md border'
            style={{ height: 'calc(100vh - 146px)' }}
        >
            <Typhography component='div' additionalClasses='border-b '>
                <Typhography>
                    <Typhography component='div' additionalClasses='flex justify-between'>
                        <Typhography
                            component='div'
                            additionalClasses='flex justify-center items-center font-semibold text-25s px-4'
                        >
                            {rowDetails ? rowDetails.challenge.challengeName : challengeTimelineData?.[0]?.challenge}
                        </Typhography>
                        <Typhography component='div' additionalClasses='flex p-4'>
                            <Button
                                buttonLabel={'Clear filter'}
                                variants='primaryWhite'
                                onClick={() => setShowChallengeTimeline(false)}
                                additionalClasses='text-nowrap'
                            ></Button>
                        </Typhography>
                    </Typhography>
                </Typhography>
            </Typhography>
            <Typhography additionalClasses='pt-4 overflow-hidden overflow-y-auto h-[calc(100vh-240px)]' component='div'>
                {challengeTimelineData && challengeTimelineData.length !== 0 ? (
                    <Timeline timelines={challengeTimelineData} showAll={true} isChallengeTimeline={true} handleCopyCurrentNote={handleCopyCurrentNote}/>
                ) : (
                    <div className='flex flex-col items-center mt-10' style={{ height: 'calc(100vh - 309px)' }}>
                        <InfoIcon40 />
                        <Typhography component='div'>
                            No previously solved challenge data
                        </Typhography>
                    </div>
                )}
            </Typhography>
        </section>
    );
};

export default ChallengeTimeline;
