// Header.tsx
import React, { useEffect, useState } from 'react';

import { LoadingSpinner, NavBar } from 'dfh-ui-library';
import { AVATAR } from 'utils/images/Images';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteRegistry from 'routes/routeRegistry';
import { LocalStorageService } from 'utils/storage/LocalStorageService';
import { useAuthRepository } from 'repositories/AuthRepository';
import { PatientsFilter } from 'utils/types/patient.type';

const Header: React.FC = () => {
    const navigate = useNavigate();
    const localStorageService = LocalStorageService();
    const authRepository = useAuthRepository();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const navItems = [
        {
            navTitle: 'Dashboard',
            icon: location.pathname.includes('dashboard') ? 'HomeSelected' : 'Home',
            navClasses: 'mr-2',
            textClass: 'pr-10',
            isActive: location.pathname === '/dashboard' || location.pathname === '/' ? true : false,
            urlPath: 'dashboard',
        },
        {
            navTitle: 'Patients',
            icon: location.pathname.includes('patient') ? 'userMenuSelected' : 'userMenu',
            navClasses: 'relative top-1',
            textClass: 'pr-4',
            isActive: location.pathname.includes('patient'),
            urlPath: 'patient',
        },
        {
            navTitle: 'Reporting',
            icon: location.pathname.includes('reporting') ? 'ReportingSelected' : 'Reporting',
            navClasses: 'relative top-1',
            textClass: 'pr-4',
            isActive: location.pathname.includes('reporting'),
            urlPath: 'reporting',
        },
    ];
    const navigateToLogin = () => {
        navigate('/auth/login');
    };
    useEffect(() => {
        const accessToken = localStorageService.getAccessToken();
        if (!accessToken) navigateToLogin();
    }, []);

    const handleNavigation = (link?: string) => {
        switch (link) {
            case 'dashboard':
                navigate(RouteRegistry.app.paths.root.path);
                break;

            case 'patient':
                navigate(`${RouteRegistry.patient.paths.list.path}?filter=${PatientsFilter.MY}`);
                break;
            case 'reporting':
                navigate(RouteRegistry.reporting.paths.list.path);
                break;
        }
    };

    const handleLogout = async () => {
        setIsLoading(true);
        try {
            const user = localStorageService.getUser();
            await authRepository.signOut({
                email: user.email,
            });
            localStorageService.clearAuthTokens();
            localStorageService.clearSelectedTimelineTabs();
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);

            navigate(RouteRegistry.auth.paths.signIn.path);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {isLoading && <LoadingSpinner />}
            <NavBar
                isEnableLogout={true}
                navigationData={navItems}
                userImage={AVATAR}
                username={localStorageService.getUser()?.fullName}
                themeColor={localStorageService.getUser()?.themeColor}
                handleLogout={handleLogout}
                navItemClick={handleNavigation}
            />
        </>
    );
};

export default Header;
