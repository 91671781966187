import { ApiResponse, ISchemaRequestParams } from 'utils/types/api.type';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { IScreeningRepository } from './IScreeningRepository';
import {
    useLazyGetPatientLetterSchemaQuery,
    useGetPatientSchemaQuery,
    useGetSchemaQuery,
    useScreenerVersionsQuery,
    useUpdateScreenerMutation,
    useUpdateLetterMutation,
    useLazyGetPatientLetterSchemaReleaseInfoQuery,
    useUpdateLetterInfoMutation,
} from 'api/screeningAPI';
import { ILetterRequest, ILetterRequestRInfo, IScreenerRequest } from 'utils/types/screener.type';

export const useScreeningRepository = (): IScreeningRepository => {
    const { data, error, isLoading, refetch } = useGetSchemaQuery({});
    const [mutateUpdateScreenerFunction] = useUpdateScreenerMutation();
    const [mutateUpdateLetterFunction] = useUpdateLetterMutation();
    const [mutateUpdateLetterRInfoFunction] = useUpdateLetterInfoMutation();
    const [letterSchemaTrigger] = useLazyGetPatientLetterSchemaQuery();
    const [letterResourcesSchemaTrigger] = useLazyGetPatientLetterSchemaReleaseInfoQuery();

    const getSchema = (): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isLoading: boolean;
        refetch: () => void;
    } => {
        return { data, error, isLoading, refetch };
    };

    const updateScreenerStep = async (screenerReq: IScreenerRequest): Promise<any> => {
        try {
            const result = mutateUpdateScreenerFunction(screenerReq); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during update screener steps:', error);
            throw new Error('An error occurred during update screener steps');
        }
    };

    const updateLetterSchemaValues = async (screenerReq: ILetterRequest): Promise<any> => {
        try {
            const result = mutateUpdateLetterFunction(screenerReq); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during update screener steps:', error);
            throw new Error('An error occurred during update screener steps');
        }
    };

    const updateLetterSchemaValuesForRInfo = async (screenerReq: ILetterRequestRInfo): Promise<any> => {
        try {
            const result = mutateUpdateLetterRInfoFunction(screenerReq); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during update screener steps:', error);
            throw new Error('An error occurred during update screener steps');
        }
    };

    const screenerVersions = (
        screenerReq: ISchemaRequestParams,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isLoading: boolean;
    } => {
        const { data, error, isLoading } = useScreenerVersionsQuery(screenerReq);
        return { data, error, isLoading };
    };

    const getPatientSchema = (
        reqParams: ISchemaRequestParams,
    ): {
        data: ApiResponse | undefined;
        error: FetchBaseQueryError | SerializedError | undefined;
        isFetching: boolean;
        refetch: () => void;
    } => {
        const { data, error, refetch, isFetching } = useGetPatientSchemaQuery(reqParams);
        return { data, error, isFetching, refetch };
    };

    const getPatientLetterSchemaReleaseInfo = (reqParams: string): Promise<any> => {
        try {
            const result = letterResourcesSchemaTrigger(reqParams); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during update screener steps:', error);
            throw new Error('An error occurred during update screener steps');
        }
    };

    const getPatientLetterSchema = (reqParams: ISchemaRequestParams): Promise<any> => {
        try {
            const result = letterSchemaTrigger(reqParams); // access the first element of the tuple
            if (result) {
                result.then((response) => {
                    return response;
                });
            }
            return result;
        } catch (error) {
            console.error('An error occurred during update screener steps:', error);
            throw new Error('An error occurred during update screener steps');
        }
    };

    return {
        getSchema,
        updateScreenerStep,
        getPatientSchema,
        screenerVersions,
        getPatientLetterSchema,
        updateLetterSchemaValues,
        getPatientLetterSchemaReleaseInfo,
        updateLetterSchemaValuesForRInfo,
    };
};
